import { getUserOrganisation, getOrganisationAdmin, addNewOrganisation, addOrganisationImage, updateOrganisation, updateOrganisationAddress, updateOrganisationContactPeople, deleteOrganisation } from '@/api/organisations'
const state = {
  organisations: null,
  organisationsStatus: null
}

const mutations = {
  setOrganisations (state, organisations) {
    state.organisations = organisations
  },
  setOrganisationStatus (state, status) {
    state.organisationsStatus = status
  }
}

const actions = {
  async setOrganisations ({ commit }, organisations) {
    await commit('setOrganisations', organisations)
  },
  async fetchOrganisations ({ dispatch }) {
    await dispatch('setOrganisationStatus', 'loading')
    const organisations = await getUserOrganisation()
    const activeOrganisations = []
    if (organisations.data.length > 0) {
      await organisations.data.forEach((organisation) => {
        if (!organisation.archive) {
          activeOrganisations.push(organisation)
        }
      })
    }
    await dispatch('setOrganisations', activeOrganisations)
    await dispatch('setOrganisationStatus', 'ready')
  },
  async addOrganisation ({ dispatch }, organisation) {
    const orgDetails = await addNewOrganisation(organisation)
    if (organisation.image) {
      const saveImage = {
        organisationID: orgDetails.data.organisationID,
        image: organisation.image
      }
      await dispatch('saveOrgImage', saveImage)
    }
    await dispatch('fetchOrganisations')
  },
  async saveOrgImage ({ _ }, image) {
    await addOrganisationImage(image)
  },
  async fetchOrganisationAdmin ({ _ }, id) {
    const adminDetails = await getOrganisationAdmin(id)
    const adminName = adminDetails.data.firstName + ' ' + adminDetails.data.lastName
    return adminName
  },
  async updateOrganisation ({ dispatch }, organisation) {
    await updateOrganisation(organisation)
    await dispatch('fetchOrganisations')
  },
  async saveOrgAddress ({ _ }, organisation) {
    await updateOrganisationAddress(organisation)
  },
  async saveOrgContactPeople ({ _ }, organisation) {
    await updateOrganisationContactPeople(organisation)
  },
  async setOrganisationStatus ({ commit }, status) {
    await commit('setOrganisationStatus', status)
  },
  async deleteOrganisation ({ dispatch }, organisationID) {
    await deleteOrganisation(organisationID)
    await dispatch('fetchOrganisations')
  }
}

const getters = {
  organisations: state => state.organisations,
  organisationsStatus: state => state.organisationsStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
