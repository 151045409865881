import axios from 'axios'
import store from '../store'

const saveZakaat = (details) => {
  if (!details.zakaatDetailID) delete details.zakaatDetailID
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/v2/saveDetails/withProfile`, details, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE',
      Authorization: `Bearer ${store.state.user.jwt}`
    }
  })
}

const saveZakaatAnon = (details) => {
  if (!details.zakaatDetailID) delete details.zakaatDetailID
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/v2/saveDetails/anonymous`, details, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const pastZakaatDetails = () => {
  if (store.state.user.user !== null) {
    return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/v2/pastZakaatDetails/${store.state.user.user.id}`, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwt}`,
        baseSystemType: 'PUBLIC_WEBSITE'
      }
    })
  }
}
const deleteZakaatDetails = (id) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/zakaatDetails/delete/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const downloadZakaatSummary = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/summary/download/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    responseType: 'blob'
  })
}

export { saveZakaat, saveZakaatAnon, pastZakaatDetails, deleteZakaatDetails, downloadZakaatSummary }
