import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
// import { getFunctions } from 'firebase/functions'
// import { getMessaging } from 'firebase/messaging'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD5EggZG7S8zl0q6BZnYPPGE5bwbho4hc4',
  authDomain: 'al-imdaad-app-88ec2.firebaseapp.com',
  databaseURL: 'https://al-imdaad-app-88ec2-default-rtdb.firebaseio.com',
  projectId: 'al-imdaad-app-88ec2',
  storageBucket: 'al-imdaad-app-88ec2.appspot.com',
  messagingSenderId: '74678962136',
  appId: '1:74678962136:web:41ae3348d289e2e3a194ad',
  measurementId: 'G-13G68HLQD9'
}

const app = initializeApp(firebaseConfig)

export const db = getDatabase(app)
// export const functions = getFunctions(app)
// export const messaging = getMessaging(app)
