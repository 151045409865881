const state = {
  isApp: process.env.VUE_APP_MODE === 'APP'
}

const getters = {
  isApp: state => state.isApp
}

export default {
  state,
  getters
}
