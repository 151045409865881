import { getNews } from '@/api/news'
const state = {
  news: []
}

const mutations = {
  setNews (state, news) {
    state.news = news
  }
}

const actions = {
  setNews ({ commit }, news) {
    commit('setNews', news)
  },
  fetchNews ({ dispatch }) {
    getNews().then((news) => {
      dispatch('setNews', news.data)
    })
  }
}

const getters = {
  news: state => state.news
}

export default {
  state,
  mutations,
  actions,
  getters
}
