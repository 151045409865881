import { pastZakaatDetails, saveZakaat, saveZakaatAnon } from '@/api/zakat'

const state = {
  currentZakaah: {},
  zakaatAssets: {},
  commodities: [],
  cashAssets: [],
  policyAssets: [],
  investmentAssets: [],
  businessAssets: [],
  debtorAssets: [],
  partnershipAssets: [],
  zakaatLiabilities: {},
  basicLiabilities: [],
  zakaatHomeLoanLiabilities: [],
  zakaatVehicleLiabilities: [],
  commoditiesTotal: 0,
  cashAssetsTotal: 0,
  policyAssetsTotal: 0,
  investmentAssetsTotal: 0,
  businessAssetsTotal: 0,
  debtorAssetsTotal: 0,
  partnershipAssetsTotal: 0,
  basicLiabilitiesTotal: 0,
  zakaatHomeLoanLiabilitiesTotal: 0,
  zakaatVehicleLiabilitiesTotal: 0,
  zakaatAssetsTotal: 0,
  zakaatLiabilitiesTotal: 0,
  pastZakaahs: []
}

const mutations = {
  setFromPast (state) {
    state.zakaatAssets = state.currentZakaah.zakaatAssets
    state.commodities = state.currentZakaah.zakaatAssets.commodities
    state.cashAssets = state.currentZakaah.zakaatAssets.cashAssets
    state.policyAssets = state.currentZakaah.zakaatAssets.policyAssets
    state.investmentAssets = state.currentZakaah.zakaatAssets.investmentAssets
    state.businessAssets = state.currentZakaah.zakaatAssets.businessAssets
    state.debtorAssets = state.currentZakaah.zakaatAssets.debtorAssets
    state.partnershipAssets = state.currentZakaah.zakaatAssets.partnershipAssets
    state.zakaatLiabilities = state.currentZakaah.zakaatLiabilities
    state.basicLiabilities = state.currentZakaah.zakaatLiabilities.basicLiabilities
    state.zakaatHomeLoanLiabilities = state.currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities
    state.zakaatVehicleLiabilities = state.currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities
  },
  calculateAll (state) {
    let retCom = 0
    state.commodities.forEach(item => {
      retCom += Number(item.amount)
    })
    state.commoditiesTotal = retCom

    let retMoney = 0
    state.cashAssets.forEach(item => {
      retMoney += Number(item.amount)
    })
    state.cashAssetsTotal = retMoney

    let retPolicies = 0
    state.policyAssets.forEach(item => {
      retPolicies += Number(item.amount)
    })
    state.policyAssetsTotal = retPolicies

    let retInvestments = 0
    state.investmentAssets.forEach(item => {
      retInvestments += Number(item.amount)
    })
    state.investmentAssetsTotal = retInvestments

    let retBus = 0
    state.businessAssets.forEach(item => {
      retBus += Number(item.amount)
    })
    state.businessAssetsTotal = retBus

    let retDebtors = 0
    state.debtorAssets.forEach(item => {
      retDebtors += Number(item.amount)
    })
    state.debtorAssetsTotal = retDebtors

    let retPartners = 0
    state.partnershipAssets.forEach(item => {
      retPartners += Number(item.total)
    })
    state.partnershipAssetsTotal = retPartners

    state.zakaatAssetsTotal = Number(state.partnershipAssetsTotal) + Number(state.debtorAssetsTotal) + Number(state.businessAssetsTotal) + Number(state.investmentAssetsTotal) + Number(state.policyAssetsTotal) + Number(state.cashAssetsTotal) + Number(state.commoditiesTotal)

    let retGen = 0
    state.basicLiabilities.forEach(item => {
      retGen += Number(item.amount)
    })
    state.basicLiabilitiesTotal = retGen

    let retHome = 0
    state.zakaatHomeLoanLiabilities.forEach(item => {
      retHome += Number(item.monthlyInstalments)
    })
    state.zakaatHomeLoanLiabilitiesTotal = retHome

    let retVehicle = 0
    state.zakaatVehicleLiabilities.forEach(item => {
      retVehicle += Number(item.monthlyInstalments)
    })
    state.zakaatVehicleLiabilitiesTotal = retVehicle

    state.zakaatLiabilitiesTotal = Number(state.zakaatVehicleLiabilitiesTotal) + Number(state.zakaatHomeLoanLiabilitiesTotal) + Number(state.basicLiabilitiesTotal)
  },
  setCommodities (state) {
    state.zakaatAssets.commodities = state.commodities
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.commodities.forEach(item => {
      ret += Number(item.amount)
    })
    state.commoditiesTotal = ret
  },
  addToCommodities (state, commoditiesItem) {
    state.commodities.push(commoditiesItem)
  },
  clearCommodities (state) {
    state.commodities = []
  },
  editCommodities (state, commoditiesItem) {
    state.commodities[commoditiesItem.key] = commoditiesItem.item
  },
  deleteCommoditiesItem (state, commoditiesItemKey) {
    state.commodities.splice(commoditiesItemKey, 1)
  },

  setMoney (state) {
    state.zakaatAssets.cashAssets = state.cashAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.cashAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.cashAssetsTotal = ret
  },
  addToMoney (state, moneyItem) {
    state.cashAssets.push(moneyItem)
    state.zakaatAssets.cashAssets = state.cashAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.cashAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.cashAssetsTotal = ret
  },
  clearMoney (state) {
    state.cashAssets = []
  },
  editMoney (state, moneyItem) {
    state.cashAssets[moneyItem.key] = moneyItem.item
  },
  deleteMoneyItem (state, moneyItemKey) {
    state.cashAssets.splice(moneyItemKey, 1)
  },

  setPolicies (state) {
    state.zakaatAssets.policyAssets = state.policyAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.policyAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.policyAssetsTotal = ret
  },
  addToPolicies (state, policiesItem) {
    state.policyAssets.push(policiesItem)
  },
  clearPolicies (state) {
    state.policyAssets = []
  },
  editPolicies (state, policiesItem) {
    state.policyAssets[policiesItem.key] = policiesItem.item
  },
  deletePoliciesItem (state, policiesItemKey) {
    state.policyAssets.splice(policiesItemKey, 1)
  },

  setInvestments (state) {
    state.zakaatAssets.investmentAssets = state.investmentAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.investmentAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.investmentAssetsTotal = ret
  },
  addToInvestments (state, investmentsItem) {
    state.investmentAssets.push(investmentsItem)
  },
  clearInvestments (state) {
    state.investmentAssets = []
  },
  editInvestments (state, investmentsItem) {
    state.investmentAssets[investmentsItem.key] = investmentsItem.item
  },
  deleteInvestmentsItem (state, investmentsItemKey) {
    state.investmentAssets.splice(investmentsItemKey, 1)
  },

  setBusiness (state) {
    state.zakaatAssets.businessAssets = state.businessAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.businessAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.businessAssetsTotal = ret
  },
  addToBusiness (state, businessItem) {
    state.businessAssets.push(businessItem)
  },
  clearBusiness (state) {
    state.businessAssets = []
  },
  editBusiness (state, businessItem) {
    state.businessAssets[businessItem.key] = businessItem.item
  },
  deleteBusinessItem (state, businessItemKey) {
    state.businessAssets.splice(businessItemKey, 1)
  },

  setDebtors (state) {
    state.zakaatAssets.debtorAssets = state.debtorAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.debtorAssets.forEach(item => {
      ret += Number(item.amount)
    })
    state.debtorAssetsTotal = ret
  },
  addToDebtors (state, debtorsItem) {
    state.debtorAssets.push(debtorsItem)
  },
  clearDebtors (state) {
    state.debtorAssets = []
  },
  editDebtors (state, debtorsItem) {
    state.debtorAssets[debtorsItem.key] = debtorsItem.item
  },
  deleteDebtorsItem (state, debtorsItemKey) {
    state.debtorAssets.splice(debtorsItemKey, 1)
  },

  setPartnership (state) {
    state.zakaatAssets.partnershipAssets = state.partnershipAssets
    state.currentZakaah.zakaatAssets = state.zakaatAssets
    let ret = 0
    state.partnershipAssets.forEach(item => {
      ret += Number(item.total)
    })
    state.partnershipAssetsTotal = ret
  },
  addToPartnership (state, partnershipItem) {
    state.partnershipAssets.push(partnershipItem)
  },
  clearPartnership (state) {
    state.partnershipAssets = []
  },
  editPartnership (state, partnershipItem) {
    state.partnershipAssets[partnershipItem.key] = partnershipItem.item
  },
  deletePartnershipItem (state, partnershipItemKey) {
    state.partnershipAssets.splice(partnershipItemKey, 1)
  },

  setGeneral (state) {
    state.zakaatLiabilities.basicLiabilities = state.basicLiabilities
    state.currentZakaah.zakaatLiabilities = state.zakaatLiabilities
    let ret = 0
    state.basicLiabilities.forEach(item => {
      ret += Number(item.amount)
    })
    state.basicLiabilitiesTotal = ret
  },
  addToGeneral (state, generalItem) {
    state.basicLiabilities.push(generalItem)
  },
  clearGeneral (state) {
    state.basicLiabilities = []
  },
  editGeneral (state, generalItem) {
    state.basicLiabilities[generalItem.key] = generalItem.item
  },
  deleteGeneralItem (state, generalItemKey) {
    state.basicLiabilities.splice(generalItemKey, 1)
  },

  setHome (state) {
    state.zakaatLiabilities.zakaatHomeLoanLiabilities = state.zakaatHomeLoanLiabilities
    state.currentZakaah.zakaatLiabilities = state.zakaatLiabilities
    let ret = 0
    state.zakaatHomeLoanLiabilities.forEach(item => {
      ret += Number(item.monthlyInstalments)
    })
    state.zakaatHomeLoanLiabilitiesTotal = ret
  },
  addToHome (state, homeItem) {
    state.zakaatHomeLoanLiabilities.push(homeItem)
  },
  clearHome (state) {
    state.zakaatHomeLoanLiabilities = []
  },
  editHome (state, homeItem) {
    state.zakaatHomeLoanLiabilities[homeItem.key] = homeItem.item
  },
  deleteHomeItem (state, homeItemKey) {
    state.zakaatHomeLoanLiabilities.splice(homeItemKey, 1)
  },

  setVehicle (state) {
    state.zakaatLiabilities.zakaatVehicleLiabilities = state.zakaatVehicleLiabilities
    state.currentZakaah.zakaatLiabilities = state.zakaatLiabilities
    let ret = 0
    state.zakaatVehicleLiabilities.forEach(item => {
      ret += Number(item.monthlyInstalments)
    })
    state.zakaatVehicleLiabilitiesTotal = ret
  },
  addToVehicle (state, vehicleItem) {
    state.zakaatVehicleLiabilities.push(vehicleItem)
  },
  clearVehicle (state) {
    state.zakaatVehicleLiabilities = []
  },
  editVehicle (state, vehicleItem) {
    state.zakaatVehicleLiabilities[vehicleItem.key] = vehicleItem.item
  },
  deleteVehicleItem (state, vehicleItemKey) {
    state.zakaatVehicleLiabilities.splice(vehicleItemKey, 1)
  },

  setZakaah (state, zakaahItem) {
    state.currentZakaah = zakaahItem
  },
  clearZakaah (state) {
    state.currentZakaah = {}
  },
  editZakaah (state, zakaahItem) {
    state.currentZakaah[zakaahItem.key] = zakaahItem.item
  },
  deleteZakaahItem (state, zakaahItemKey) {
    state.currentZakaah[zakaahItemKey] = {}
  },
  setPastZakaahs (state, past) {
    state.pastZakaahs = past
  }
  // addToPastZakaahs (state, zakaahItem) {
  //   state.pastZakaahs.push(zakaahItem)
  // },
  // clearZakaahs (state) {
  //   state.pastZakaahs = []
  // },
  // editZakaahs (state, zakaahItem) {
  //   state.pastZakaahs[zakaahItem.key] = zakaahItem.item
  // },
  // deleteZakaahsItem (state, zakaahItemKey) {
  //   state.pastZakaahs.splice(zakaahItemKey, 1)
  // }
}

const actions = {
  setFromPast ({ commit }) {
    commit('setFromPast')
  },
  addToCommodities ({ commit }, item) {
    commit('addToCommodities', item)
    commit('setCommodities')
    commit('calculateAll')
  },
  clearCommodities ({ commit }) {
    commit('clearCommodities')
    commit('setCommodities')
    commit('calculateAll')
  },
  editCommodities ({ commit }, commoditiesItem) {
    commit('editCommodities', commoditiesItem)
    commit('setCommodities')
    commit('calculateAll')
  },
  deleteCommoditiesItem ({ commit }, commoditiesItemKey) {
    commit('deleteCommoditiesItem', commoditiesItemKey)
    commit('setCommodities')
    commit('calculateAll')
  },
  addToMoney ({ commit }, item) {
    commit('addToMoney', item)
    commit('setMoney')
    commit('calculateAll')
  },
  clearMoney ({ commit }) {
    commit('clearMoney')
    commit('setMoney')
    commit('calculateAll')
  },
  editMoney ({ commit }, moneyItem) {
    commit('editMoney', moneyItem)
    commit('setMoney')
    commit('calculateAll')
  },
  deleteMoneyItem ({ commit }, moneyItemKey) {
    commit('deleteMoneyItem', moneyItemKey)
    commit('setMoney')
    commit('calculateAll')
  },

  addToPolicies ({ commit }, item) {
    commit('addToPolicies', item)
    commit('setPolicies')
    commit('calculateAll')
  },
  clearPolicies ({ commit }) {
    commit('clearPolicies')
    commit('setPolicies')
    commit('calculateAll')
  },
  editPolicies ({ commit }, policyItem) {
    commit('editPolicies', policyItem)
    commit('setPolicies')
    commit('calculateAll')
  },
  deletePoliciesItem ({ commit }, policyItemKey) {
    commit('deletePoliciesItem', policyItemKey)
    commit('setPolicies')
    commit('calculateAll')
  },

  addToInvestments ({ commit }, item) {
    commit('addToInvestments', item)
    commit('setInvestments')
    commit('calculateAll')
  },
  clearInvestments ({ commit }) {
    commit('clearInvestments')
    commit('setInvestments')
    commit('calculateAll')
  },
  editInvestments ({ commit }, investmentItem) {
    commit('editInvestments', investmentItem)
    commit('setInvestments')
    commit('calculateAll')
  },
  deleteInvestmentsItem ({ commit }, investmentItemKey) {
    commit('deleteInvestmentsItem', investmentItemKey)
    commit('setInvestments')
    commit('calculateAll')
  },

  addToBusiness ({ commit }, item) {
    commit('addToBusiness', item)
    commit('setBusiness')
    commit('calculateAll')
  },
  clearBusiness ({ commit }) {
    commit('clearBusiness')
    commit('setBusiness')
    commit('calculateAll')
  },
  editBusiness ({ commit }, businessItem) {
    commit('editBusiness', businessItem)
    commit('setBusiness')
    commit('calculateAll')
  },
  deleteBusinessItem ({ commit }, businessItemKey) {
    commit('deleteBusinessItem', businessItemKey)
    commit('setBusiness')
    commit('calculateAll')
  },

  addToDebtors ({ commit }, item) {
    commit('addToDebtors', item)
    commit('setDebtors')
    commit('calculateAll')
  },
  clearDebtors ({ commit }) {
    commit('clearDebtors')
    commit('setDebtors')
    commit('calculateAll')
  },
  editDebtors ({ commit }, debtorsItem) {
    commit('editDebtors', debtorsItem)
    commit('setDebtors')
    commit('calculateAll')
  },
  deleteDebtorsItem ({ commit }, debtorsItemKey) {
    commit('deleteDebtorsItem', debtorsItemKey)
    commit('setDebtors')
    commit('calculateAll')
  },

  addToPartnership ({ commit }, item) {
    commit('addToPartnership', item)
    commit('setPartnership')
    commit('calculateAll')
  },
  clearPartnership ({ commit }) {
    commit('clearPartnership')
    commit('setPartnership')
    commit('calculateAll')
  },
  editPartnership ({ commit }, partnershipItem) {
    commit('editPartnership', partnershipItem)
    commit('setPartnership')
    commit('calculateAll')
  },
  deletePartnershipItem ({ commit }, partnershipItemKey) {
    commit('deletePartnershipItem', partnershipItemKey)
    commit('setPartnership')
    commit('calculateAll')
  },

  addToGeneral ({ commit }, item) {
    commit('addToGeneral', item)
    commit('setGeneral')
    commit('calculateAll')
  },
  clearGeneral ({ commit }) {
    commit('clearGeneral')
    commit('setGeneral')
    commit('calculateAll')
  },
  editGeneral ({ commit }, item) {
    commit('editGeneral', item)
    commit('setGeneral')
    commit('calculateAll')
  },
  deleteGeneralItem ({ commit }, itemKey) {
    commit('deleteGeneralItem', itemKey)
    commit('setGeneral')
    commit('calculateAll')
  },

  addToHome ({ commit }, item) {
    commit('addToHome', item)
    commit('setHome')
    commit('calculateAll')
  },
  clearHome ({ commit }) {
    commit('clearHome')
    commit('setHome')
    commit('calculateAll')
  },
  editHome ({ commit }, item) {
    commit('editHome', item)
    commit('setHome')
    commit('calculateAll')
  },
  deleteHomeItem ({ commit }, itemKey) {
    commit('deleteHomeItem', itemKey)
    commit('setHome')
    commit('calculateAll')
  },

  addToVehicle ({ commit }, item) {
    commit('addToVehicle', item)
    commit('setVehicle')
    commit('calculateAll')
  },
  clearVehicle ({ commit }) {
    commit('clearVehicle')
    commit('setVehicle')
    commit('calculateAll')
  },
  editVehicle ({ commit }, item) {
    commit('editVehicle', item)
    commit('setVehicle')
    commit('calculateAll')
  },
  deleteVehicleItem ({ commit }, itemKey) {
    commit('deleteVehicleItem', itemKey)
    commit('setVehicle')
    commit('calculateAll')
  },

  async setZakaah ({ dispatch, commit }, item) {
    await commit('setZakaah', item)
    await dispatch('setFromPast')
    await commit('calculateAll')
  },
  clearZakaah ({ commit }) {
    commit('clearZakaah')
  },
  editZakaah ({ commit }, zakaahItem) {
    let total = 0
    zakaahItem.item.donationTypes.forEach(donationType => {
      total += Number(donationType.amount)
    })
    zakaahItem.item.amount = total
    commit('editZakaah', zakaahItem)
  },
  deleteZakaahItem ({ commit }, zakaahItemKey) {
    commit('deleteZakaahItem', zakaahItemKey)
  },
  // addToZakaahs ({ commit }, item) {
  //   commit('addToZakaahs', item)
  // },
  // clearZakaahs ({ commit }) {
  //   commit('clearZakaahs')
  // },
  // editZakaahs ({ commit }, zakaahItem) {
  //   let total = 0
  //   zakaahItem.item.donationTypes.forEach(donationType => {
  //     total += Number(donationType.amount)
  //   })
  //   zakaahItem.item.amount = total
  //   commit('editZakaahs', zakaahItem)
  // },
  // deleteZakaahsItem ({ commit }, zakaahItemKey) {
  //   commit('deleteZakaahsItem', zakaahItemKey)
  // }
  async setPastZakaahs ({ dispatch, commit }, past) {
    await commit('setPastZakaahs', past)
    await dispatch('setFromPast')
  },
  fetchPastZakaahs ({ dispatch, rootState }) {
    if (rootState.user.user !== null) {
      pastZakaatDetails().then((past) => {
        dispatch('setPastZakaahs', past.data)
      })
    }
  },
  async saveZakaah ({ dispatch, rootState }, item) {
    if (rootState.user.user !== null) {
      await saveZakaat(item)
    } else {
      await saveZakaatAnon(item)
    }
    dispatch('fetchPastZakaat', { root: true })
    // this.zakaatDetailID = response.data.zakaatDetailID
  }
}

const getters = {
  currentZakaah: state => state.currentZakaah,
  zakaatAssets: state => state.zakaatAssets,
  commodities: state => state.commodities,
  cashAssets: state => state.cashAssets,
  investmentAssets: state => state.investmentAssets,
  policyAssets: state => state.policyAssets,
  businessAssets: state => state.businessAssets,
  debtorAssets: state => state.debtorAssets,
  partnershipAssets: state => state.partnershipAssets,
  pastZakaahs: state => state.pastZakaahs,
  zakaatLiabilities: state => state.zakaatLiabilities,
  basicLiabilities: state => state.basicLiabilities,
  zakaatHomeLoanLiabilities: state => state.zakaatHomeLoanLiabilities,
  zakaatVehicleLiabilities: state => state.zakaatVehicleLiabilities,
  commoditiesTotal: state => state.commoditiesTotal,
  cashAssetsTotal: state => state.cashAssetsTotal,
  policyAssetsTotal: state => state.policyAssetsTotal,
  investmentAssetsTotal: state => state.investmentAssetsTotal,
  businessAssetsTotal: state => state.businessAssetsTotal,
  debtorAssetsTotal: state => state.debtorAssetsTotal,
  partnershipAssetsTotal: state => state.partnershipAssetsTotal,
  zakaatLiabilitiesTotal: state => state.zakaatLiabilitiesTotal,
  basicLiabilitiesTotal: state => state.basicLiabilitiesTotal,
  zakaatHomeLoanLiabilitiesTotal: state => state.zakaatHomeLoanLiabilitiesTotal,
  zakaatVehicleLiabilitiesTotal: state => state.zakaatVehicleLiabilitiesTotal,
  zakaatAssetsTotal: state => state.zakaatAssetsTotal
}

export default {
  state,
  mutations,
  actions,
  getters
}
