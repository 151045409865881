<template>
  <FullPopup v-if="showSwitcher">
    <div class="row mx-0 p-3">
      <div class="col-12 p-0">
        <div class="row m-0 justify-content-center">
          <div class="col-9 col-md-auto text-center px-0 pb-3 border_bottom mb-3 green-text font23 medium">
            Switch Site Region & Currency
          </div>
        </div>
        <div class="row m-0 justify-content-center">
          <div class="col-9 text-center">
            We detected that you are accessing our <span class="bold green-text">{{siteDetails.currentSite.display}}</span> site from <span class="bold green-text">{{siteDetails.fromLocation.display}}</span>
          </div>
          <div class="col-md-9 p-0 text-center">
            <div class="row justify-content-center my-3 g-2 g-md-3">
              <div class="col-6">
                <div class="row mx-0 p-2 p-md-3 border border-3 rounded-4">
                  <div class="col-12 px-0 text-center">
                    <img v-if="siteDetails.currentSite.icon" :src="require('@/assets/images/' + siteDetails.currentSite.icon + '.png')" class="img-fluid round_image">
                    <div class="bold green-text mt-2">
                    {{ siteDetails.currentSite.name }}
                    </div>
                    <div class="bold green-text mb-2">
                    {{ siteDetails.currentSite.currency }}
                    </div>
                    <Button color="goldGreen" width="100" class="px-2" :btnText="siteDetails.currentSite.buttonText" @buttonClicked="continueTo" />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row mx-0 p-2 p-md-3 border border-3 rounded-4 borderActive">
                  <div class="col-12 px-0 text-center">
                    <img v-if="siteDetails.fromLocation.icon" :src="require('@/assets/images/' + siteDetails.fromLocation.icon + '.png')" class="img-fluid round_image">
                    <!-- <Image :imageName="siteDetails.fromLocation.icon" width="50" theClass="img-fluid round_image" /> -->
                    <div class="bold green-text mt-2">
                    {{ siteDetails.fromLocation.name }}
                    </div>
                    <div class="bold green-text mb-2">
                    {{ siteDetails.fromLocation.currency }}
                    </div>
                    <Button color="goldGreen" width="100" class="px-2" :btnText="siteDetails.fromLocation.buttonText" @buttonClicked="switchTo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FullPopup>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    FullPopup: defineAsyncComponent(() => import('@/components/styling/FullPopup.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue'))
  },
  data () {
    return {
      countryLocation: ''
    }
  },
  async mounted () {
    this.countryLocation = await this.getLocation()
  },
  computed: {
    ...mapGetters([
      'isMobile', 'selectedLocation'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    otherUrl () {
      return process.env.VUE_APP_OTHER_URL_BASE
    },
    siteDetails () {
      const details = {
        currentSite: {
          display: '',
          country: '',
          name: '',
          icon: '',
          currency: '',
          buttonText: 'Continue'
        },
        fromLocation: {
          display: '',
          country: '',
          name: '',
          icon: '',
          currency: '',
          url: '',
          buttonText: 'Switch to'
        }
      }
      if (this.isUK) {
        details.currentSite.display = 'United Kingdom'
        details.currentSite.country = 'UK'
        details.currentSite.name = 'United Kingdom'
        details.currentSite.icon = 'uk_image'
        details.currentSite.currency = 'GBP (£)'
      } else {
        details.currentSite.display = 'South African'
        details.currentSite.country = 'ZA'
        details.currentSite.name = 'South Africa'
        details.currentSite.icon = 'za_image'
        details.currentSite.currency = 'ZAR (R)'
      }
      if (this.countryLocation === 'GB') {
        details.fromLocation.display = 'the United Kingdom'
        details.fromLocation.country = 'UK'
        details.fromLocation.name = 'United Kingdom'
        details.fromLocation.icon = 'uk_image'
        details.fromLocation.currency = 'GBP (£)'
        details.fromLocation.url = this.otherUrl
        details.fromLocation.buttonText = 'Switch to UK site'
        if (this.isMobile) {
          details.fromLocation.buttonText = 'Switch to UK'
        }
      } else if (this.countryLocation === 'ZA') {
        details.fromLocation.display = 'South Africa'
        details.fromLocation.country = 'ZA'
        details.fromLocation.name = 'South Africa'
        details.fromLocation.icon = 'za_image'
        details.fromLocation.currency = 'ZAR (R)'
        details.fromLocation.url = this.otherUrl
        details.fromLocation.buttonText = 'Switch to ZA site'
        if (this.isMobile) {
          details.fromLocation.buttonText = 'Switch to ZA'
        }
      }
      return details
    },
    showSwitcher () {
      if (!this.selectedLocation && this.countryLocation && this.countryLocation !== 'Error') {
        if (this.isUK && this.countryLocation !== 'GB') {
          return true
        } else if (!this.isUK && this.countryLocation === 'GB') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(['getLocation', 'setLocation']),
    switchTo () {
      window.location.href = this.siteDetails.fromLocation.url
    },
    continueTo () {
      this.setLocation(this.siteDetails.currentSite.country)
    }
  }
}

</script>

<style scoped>
.borderActive {
  border-color: var(--green-color) !important;
}
</style>
