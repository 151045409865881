import { createRouter, createWebHashHistory } from 'vue-router'
const country = process.env.VUE_APP_COUNTRY
let donationCheckout = {
  path: '/checkout/:id',
  name: 'Donation Checkout',
  component: () => import(/* webpackChunkName: "group-donate" */ '../pages/checkout/Checkout.vue')
}
if (country === 'UK') {
  donationCheckout = {
    path: '/checkout/:id',
    name: 'Donation Checkout',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/checkout/CheckoutUk.vue')
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home.vue')
  },
  {
    path: '/reset',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/resetpassword/ResetPassword.vue'),
    props: route => ({ query: route.query })
  },
  {
    path: '/profile/:id',
    name: 'Donor Profile',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/Profile.vue')
  },
  {
    path: '/profileedit',
    name: 'Edit Profile',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/ProfileEdit.vue')
  },
  {
    path: '/donorgroups',
    name: 'Donor Groups',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/donorgroups/DonorGroups.vue')
  },
  {
    path: '/donorgroupconfirmation',
    name: 'Donor Group Confirmation',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/donorgroups/DonorGroupConfirmation.vue'),
    props: route => ({ query: route.query.donorGroupInvite })
  },
  {
    path: '/grouptransactions/:id',
    name: 'Group Transactions',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/donorgroups/GroupTransactions.vue')
  },
  {
    path: '/managezakaah',
    name: 'Zakaah Calculations',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/zakaah/ManageZakaah.vue')
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/organisations/Organisations.vue')
  },
  {
    path: '/organisationnew',
    name: 'New Organisation',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/organisations/OrganisationNew.vue')
  },
  {
    path: '/organisationedit/:id',
    name: 'Edit Organisation',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/organisations/OrganisationEdit.vue')
  },
  {
    path: '/organisationdonations/:id',
    name: 'Organisation Donations',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/organisations/OrganisationDonations.vue')
  },
  {
    path: '/managesadaqah',
    name: 'Manage Daily Sadaqah',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/sadaqah/ManageSadaqah.vue')
  },
  {
    path: '/manage18a',
    name: 'Manage Section 18A',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/18a/Manage18A.vue')
  },
  {
    path: '/giftaidpreferences',
    name: 'GiftAid Preferences',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/manage/giftaid/GiftAidPreferences.vue')
  },
  {
    path: '/dailysadaqah',
    name: 'Daily Sadaqah',
    component: () => import(/* webpackChunkName: "home" */ '../pages/dailysadaqah/DailySadaqah.vue')
  },
  donationCheckout,
  {
    path: '/editcart/:id',
    name: 'Edit Cart Item',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/EditIndividualCart.vue')
  },
  {
    path: '/donate',
    name: 'Donate Now',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/donate/Donate.vue')
  },
  {
    path: '/paymentcomplete',
    name: 'Payment Complete',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/paymentcomplete/za/PaymentComplete.vue')
  },
  {
    path: '/recurringpaymentcomplete/:subscriptionRef/:donationId',
    name: 'Recurring Payment Complete',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/paymentcomplete/za/RecurringCreditCard.vue')
  },
  {
    path: '/paymentcompleteUk',
    name: 'Payment Complete ',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/paymentcomplete/uk/PaymentCompleteUK.vue'),
    props: route => ({ query: route.query })
  },
  {
    path: '/recurringpaymentcompleteUk/:subscriptionRef/:donationId',
    name: 'Recurring Payment Complete ',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/paymentcomplete/uk/PaymentCompleteRecurringUK.vue')
  },
  {
    path: '/recurringdonations',
    name: 'Recurring Donations ',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/RecurringDonations.vue')
  },
  {
    path: '/managerecurringdonations',
    name: 'Recurring Donations',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/profile/pages/manage/recurringdonations/ManageRecurringDonations.vue')
  },
  {
    path: '/debitorders',
    name: 'Debit Orders',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/DebitOrders.vue')
  },
  {
    path: '/pledges',
    name: 'Pledged Donations',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/Pledges.vue')
  },
  {
    path: '/appeals/:id',
    name: 'Appeals',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/appeals/Appeals.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/news/News.vue')
  },
  {
    path: '/news/:id',
    name: 'News Article',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/news/NewsIndividual.vue')
  },
  {
    path: '/ourwork',
    name: 'Our Work',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/ourwork/OurWork.vue')
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/project/ProjectIndividual.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/contact/Contact.vue')
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/about/AboutUs.vue')
  },
  {
    path: '/section18a',
    name: 'Section 18A',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/section18a/Section18A.vue')
  },
  {
    path: '/giftaid',
    name: 'The Gift Aid Scheme',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/giftaid/GiftAid.vue')
  },
  {
    path: '/volunteer',
    name: 'Volunteer',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/volunteer/Volunteer.vue')
  },
  {
    path: '/waystodonate',
    name: 'Donation Methods',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/waystodonate/WaysToDonate.vue')
  },
  {
    path: '/zakaahcalculator/:id',
    name: 'Zakaah Calculator',
    component: () => import(/* webpackChunkName: "group-resources" */'../pages/resources/zakaahcalculator/ZakaahCalculator.vue')
  },
  {
    path: '/verifiedfundraisers',
    name: 'Verified Fundraisers',
    component: () => import(/* webpackChunkName: "group-resources" */'../pages/resources/verifiedfundraisers/VerifiedFundraisers.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/search/SearchPage.vue')
  },
  {
    path: '/appdownload',
    name: 'App Download',
    component: () => import(/* webpackChunkName: "group-resources" */ '../pages/resources/appdownload/AppDownload.vue')
  },
  {
    path: '/donationspolicy',
    name: 'Donations Policy',
    component: () => import(/* webpackChunkName: "group-terms" */ '../pages/legal/DonationsPolicy.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "group-terms" */ '../pages/legal/PrivacyPolicy.vue')
  },
  {
    path: '/terms',
    name: 'Terms & Conditions',
    component: () => import(/* webpackChunkName: "group-terms" */ '../pages/legal/Terms.vue')
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import(/* webpackChunkName: "group-terms" */ '../pages/legal/Disclaimer.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeResolve(async to => {
  window.scrollTo(0, 0)
})
export default router
