import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import Vue3TouchEvents from 'vue3-touch-events'

require('vue-ionicons/ionicons.css')
require('swiper/swiper.scss')
require('swiper/components/navigation/navigation.scss')
require('swiper/components/pagination/pagination.scss')
require('swiper/components/scrollbar/scrollbar.scss')
require('../node_modules/bootstrap/dist/css/bootstrap.min.css')
require('./assets/css/custom.css')
require('./assets/css/slider.css')

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Vue3TouchEvents)

app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG
  }
}, router)

app.mount('#app')

// createApp(App).use(store).use(router).use(Vue3TouchEvents).use(VueGtag, {
//   property: {
//     id: process.env.VUE_APP_GTAG
//   }
// }).mount('#app')
