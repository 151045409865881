import axios from 'axios'

const foreignExchange = (date) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/currencies/exchange/${date}`)
  // return axios.get(`https://api.exchangeratesapi.io/${date}?base=${process.env.VUE_APP_CURRENCY_SYMBOL}&symbols=USD,AUD,GBP,EUR`)
}

const convertGregorianToHijri = (date) => {
  // date format DD-MM-YYYY
  return axios.get(`https://api.aladhan.com/v1/gToH?date=${date}`)
}

const goldSpotPricesPerOunce = (dateToFilterBy) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/goldSpotPricesPerOunce/${dateToFilterBy}`)
}
const silverSpotPricesPerOunce = (dateToFilterBy) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/silverSpotPricesPerOunce/${dateToFilterBy}`)
}

const goldSpotPricesPerGram = (dateToFilterBy) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/goldSpotPricesPerGram/${dateToFilterBy}`)
}

const silverSpotPricesPerGram = (dateToFilterBy) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/silverSpotPricesPerGram/${dateToFilterBy}`)
}

export {
  foreignExchange,
  convertGregorianToHijri,
  goldSpotPricesPerOunce,
  silverSpotPricesPerOunce,
  goldSpotPricesPerGram,
  silverSpotPricesPerGram
}
