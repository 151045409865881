import { foreignExchange, convertGregorianToHijri, goldSpotPricesPerOunce, silverSpotPricesPerOunce, goldSpotPricesPerGram, silverSpotPricesPerGram } from '@/api/rates'
const state = {
  exchangeRate: null,
  gregorianToHijri: null,
  goldRatePerOunce: null,
  silverRatePerOunce: null,
  goldRatePerGram: null,
  silverRatePerGram: null
}

const mutations = {
  setExchangeRate (state, exchangeRate) {
    state.exchangeRate = exchangeRate
  },
  setGregorianToHijri (state, gregorianToHijri) {
    state.gregorianToHijri = gregorianToHijri
  },
  setGoldSpotPricesPerOunce (state, goldRate) {
    state.goldRatePerOunce = goldRate
  },
  setSilverSpotPricesPerOunce (state, silverRate) {
    state.silverRatePerOunce = silverRate
  },
  setGoldSpotPricesPerGram (state, goldRate) {
    state.goldRatePerGram = goldRate
  },
  setSilverSpotPricesPerGram (state, silverRate) {
    state.silverRatePerGram = silverRate
  }
}

const actions = {
  async setExchangeRate ({ commit }, date) {
    const exchangeResponse = await foreignExchange(date)
    commit('setExchangeRate', exchangeResponse.data)
  },
  async setGregorianToHijri ({ commit }, date) {
    const dateResponse = await convertGregorianToHijri(date)
    commit('setGregorianToHijri', dateResponse.data)
  },
  async setGoldSpotPricesPerOunce ({ commit }, date) {
    const goldResponse = await goldSpotPricesPerOunce(date)
    commit('setGoldSpotPricesPerOunce', goldResponse.data)
  },
  async setSilverSpotPricesPerOunce ({ commit }, date) {
    const silverResponse = await silverSpotPricesPerOunce(date)
    commit('setSilverSpotPricesPerOunce', silverResponse.data)
  },
  async setGoldSpotPricesPerGram ({ commit }, date) {
    const goldResponse = await goldSpotPricesPerGram(date)
    commit('setGoldSpotPricesPerGram', goldResponse.data)
  },
  async setSilverSpotPricesPerGram ({ commit }, date) {
    const silverResponse = await silverSpotPricesPerGram(date)
    commit('setSilverSpotPricesPerGram', silverResponse.data)
  }
}

const getters = {
  exchangeRate: state => state.exchangeRate,
  gregorianToHijri: state => state.gregorianToHijri,
  goldRatePerOunce: state => state.goldRatePerOunce,
  silverRatePerOunce: state => state.silverRatePerOunce,
  goldRatePerGram: state => state.goldRatePerGram,
  silverRatePerGram: state => state.silverRatePerGram
}

export default {
  state,
  mutations,
  actions,
  getters
}
