import axios from 'axios'
import store from '../store'

const getTransactionsCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/count/users/find/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getTaxCertificateCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/count/taxCertificate/find/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getPledgeCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/pledge/count/users/find/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getGroupCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/count/all/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getOrganisationsCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/count/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getZakaatCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/zakaat/count/zakaatDetails/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { getTransactionsCount, getTaxCertificateCount, getPledgeCount, getGroupCount, getOrganisationsCount, getZakaatCount }
