import { getImageUrl } from '@/api/image'

const actions = {
  async getImage ({ _ }, details) {
    const image = await getImageUrl(details.imageName, details.width, details.height)
    if (image) {
      return image.data[0]
    }
  }
}

export default {
  actions
}
