import { getDonationReference, getGroupDonationReference, creditCardPayFast, getPayFastTransactionStatus, getReceiptUrl, getBankDonationRecurringReference, getBankDonationReference, debitOrdersZA } from '@/api/donations'
const FileDownload = require('js-file-download')

const PAYMENT_COMPLETE_PAYFAST_URL = `${location.protocol}//${location.host}/#/paymentcomplete`
const state = {
  cart: [],
  newCart: [],
  lastTransactionId: null,
  lastTransactionRef: null,
  lastTransaction: null,
  giftAid: false
}

const mutations = {
  addToCart (state, cartItem) {
    state.newCart = []
    state.newCart.push(cartItem)
    if (state.cart.length > 0) {
      const theIndex = state.cart.findIndex(el => el.projectId === cartItem.projectId)
      if (cartItem.plaqueDescription && cartItem.plaqueDescription !== '') {
        state.cart.push(cartItem)
      } else if (theIndex >= 0 && (!state.cart[theIndex].plaqueDescription || state.cart[theIndex].plaqueDescription !== '')) {
        const numberTotal = parseFloat(state.cart[theIndex].amount)
        const numberCartItemTotal = parseFloat(cartItem.amount)
        const finalTotal = numberTotal + numberCartItemTotal
        state.cart[theIndex].amount = finalTotal.toString()
        state.cart[theIndex].donationTypes.forEach((dType, i) => {
          let numberDTotal = 0
          if (dType.amount) {
            numberDTotal = parseFloat(dType.amount)
          }
          let numberCartItemDTotal = 0
          if (cartItem.donationTypes[i].amount) {
            numberCartItemDTotal = parseFloat(cartItem.donationTypes[i].amount)
          }
          const finalDTotal = numberDTotal + numberCartItemDTotal
          if (finalDTotal > 0) {
            dType.amount = finalDTotal.toString()
          } else {
            dType.amount = null
          }
        })
      } else {
        state.cart.push(cartItem)
      }
    } else {
      state.cart.push(cartItem)
    }
  },
  clearCart (state) {
    state.cart = []
  },
  setGiftAid (state, giftAid) {
    state.giftAid = giftAid
  },
  editCart (state, cartItem) {
    state.cart[cartItem.key] = cartItem.item
  },
  updateCart (state, updatedCart) {
    state.cart = updatedCart
  },
  deleteCartItem (state, cartItemKey) {
    state.cart.splice(cartItemKey, 1)
  },
  setLastTransactionId (state, transactionId) {
    state.lastTransactionId = transactionId
  },
  setLastTransaction (state, transaction) {
    state.lastTransaction = transaction
  },
  setLastTransactionRef (state, transactionRef) {
    state.lastTransactionRef = transactionRef
  }
}

const actions = {
  addToCart ({ commit }, item) {
    commit('addToCart', item)
  },
  clearCart ({ commit }) {
    commit('clearCart')
  },
  setGiftAid ({ commit }, giftAid) {
    commit('setGiftAid', giftAid)
  },
  editCart ({ commit }, cartItem) {
    let total = 0
    cartItem.item.donationTypes.forEach(donationType => {
      total += Number(donationType.amount)
    })
    cartItem.item.amount = total
    commit('editCart', cartItem)
  },
  updateCart ({ commit }, updatedCart) {
    commit('clearCart')
    commit('updateCart', updatedCart)
  },
  deleteCartItem ({ commit }, cartItemKey) {
    commit('deleteCartItem', cartItemKey)
  },
  getPayFastTransactionStatus ({ state }) {
    return getPayFastTransactionStatus(state.lastTransactionRef)
  },
  getReceiptUrl ({ state }, resource) {
    let donorId = `AIF${state.lastTransaction.userID}`
    if (state.lastTransaction.organisationID) {
      donorId = state.lastTransaction.organisationID
    }
    getReceiptUrl(state.lastTransactionId, resource).then((result) => {
      FileDownload(result.data, `${donorId}_${state.lastTransaction.transactionReference}.pdf`)
    })
  },
  async getTransactionReference ({ commit, getters }, checkoutDetails) {
    var transactionReference = null
    const email = (getters.user ? getters.user.email : '')
    const donationsList = []
    let currencyID = 1
    if (checkoutDetails.cart[0].currency !== 'R') {
      currencyID = 3
    }
    if (checkoutDetails.groupID) {
      checkoutDetails.cart.forEach(item => {
        let obj = {}
        item.donationTypes.forEach(type => {
          if (type.amount && type.amount > 0) {
            const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
            obj = {
              donorID: (getters.user ? getters.user.donorID : ''),
              amount: type.amount,
              donationTypeID: donationTypeID,
              projectID: item.projectId ? item.projectId : null,
              notes: item.comment || '',
              paymentGatewayResponse: '',
              currencyId: currencyID
            }
            donationsList.push(obj)
          }
        })
      })
      transactionReference = await getGroupDonationReference(donationsList, checkoutDetails)
    } else if (!getters.user) {
      checkoutDetails.cart.forEach(item => {
        let obj = {}
        item.donationTypes.forEach(type => {
          if (type.amount && type.amount > 0) {
            const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
            let hasPlaque = false
            if (item.plaqueDescription) {
              hasPlaque = true
            }
            obj = {
              email: email,
              donorID: (getters.user ? getters.user.donorID : ''),
              amount: type.amount,
              donationTypeID: donationTypeID,
              projectID: item.projectId ? item.projectId : null,
              notes: item.comment || '',
              personalizedPlaque: hasPlaque,
              plaqueDescription: item.plaqueDescription,
              paymentGatewayResponse: '',
              currencyId: currencyID
            }
            donationsList.push(obj)
          }
        })
      })
    } else {
      checkoutDetails.cart.forEach(item => {
        let obj = {}
        item.donationTypes.forEach(type => {
          if (type.amount && type.amount > 0) {
            const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
            let hasPlaque = false
            if (item.plaqueDescription) {
              hasPlaque = true
            }
            obj = {
              email: email,
              donorID: (getters.user ? getters.user.donorID : ''),
              amount: type.amount,
              donationTypeID: donationTypeID,
              projectID: item.projectId ? item.projectId : null,
              notes: item.comment || '',
              personalizedPlaque: hasPlaque,
              plaqueDescription: item.plaqueDescription,
              paymentGatewayResponse: '',
              currencyId: currencyID
            }
            donationsList.push(obj)
          }
        })
      })
    }
    console.log('checkoutDetails', checkoutDetails)
    transactionReference = await getDonationReference(0, email, donationsList, checkoutDetails)

    commit('setLastTransactionRef', transactionReference.data.transactionReference)
    commit('setLastTransactionId', transactionReference.data.id)
    commit('setLastTransaction', transactionReference.data)
    return transactionReference
  },
  async checkout ({ state, dispatch }, token) {
    if (process.env.VUE_APP_COUNTRY === 'UK') {
      console.log('checkout - UK - DISPATCH')
      return await dispatch('initiateStripePayment', token, { root: true })
      // const details = await creditCard(state.lastTransactionRef, PAYMENT_COMPLETE_URL, token)
      // window.location = details.data.redirect.url
      // return details.data.id
    } else {
      const details = await creditCardPayFast(state.lastTransactionRef, PAYMENT_COMPLETE_PAYFAST_URL, token)
      if (details && details.data) {
        return details.data
      }
    }
  },
  async bankDonationOnceOff ({ _ }, donation) {
    const transactionReference = await getBankDonationReference(donation)
    return transactionReference
  },
  async bankDonationRecurring ({ _ }, donation) {
    const transactionReference = await getBankDonationRecurringReference(donation)
    return transactionReference
  },
  async debitOrdersZA ({ dispatch }, donation) {
    const transactionReference = await debitOrdersZA(donation)
    dispatch('clearCart')
    dispatch('fetchUserDebitOrders', { root: true })
    return transactionReference
  }
}

const getters = {
  cart: state => state.cart,
  newCart: state => state.newCart,
  lastTransactionRef: state => state.lastTransactionRef
}

export default {
  state,
  mutations,
  actions,
  getters
}
