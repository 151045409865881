import { getPayFastSubscriptionStatus, verifyStripeSubscription } from '@/api/recurringDonations/getStatus'

const actions = {
  getPayFastSubscriptionStatus ({ _ }, SubscriptionRef) {
    return getPayFastSubscriptionStatus(SubscriptionRef)
  },
  verifyStripeSubscription ({ _ }, SubscriptionRef) {
    console.log('verifyStripeSubscription', SubscriptionRef)
    return verifyStripeSubscription(SubscriptionRef)
  }
}

export default {
  actions
}
