import axios from 'axios'
import store from '../../store'

const getAllRecurringDonations = (userId, startPosition, pageSize) => {
  console.log('getAllRecurringDonations API', userId, startPosition, pageSize)

  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/user/${userId}?startPosition=${startPosition}&pageSize=${pageSize}&sortField=recurringDonationID&sortOrder=DESC`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getAllDailySadaqahRecurringDonations = (userId, startPosition, pageSize) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/dailySadaqa/user/${userId}?startPosition=${startPosition}&pageSize=${pageSize}&sortField=recurringDonationID&sortOrder=DESC`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getIndividualDebitOrder = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const getIndividualCreditCardSubscription = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/creditCardSubscription/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { getAllRecurringDonations, getAllDailySadaqahRecurringDonations, getIndividualDebitOrder, getIndividualCreditCardSubscription }
