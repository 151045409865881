import axios from 'axios'
const initiateStripe = (transactionReference, returnUrl, captchaToken) => {
  console.log('initiateStripe API - ', transactionReference, returnUrl, captchaToken)
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/initiate/stripe/creditCard`, {
    transactionReference,
    returnUrl,
    captchaToken
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const cancelStripe = (transactionRef, paymentIntentID) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/cancel-payment/stripe/creditCard`, {
    transactionReference: transactionRef,
    paymentIntentID: paymentIntentID
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const verifyStripe = (transactionRef, paymentIntentID) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/creditCard/stripe/verify`, {
    transactionReference: transactionRef,
    paymentIntentID: paymentIntentID
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}

export {
  initiateStripe,
  cancelStripe,
  verifyStripe
}
