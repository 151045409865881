const state = {
  donation: []
}

const mutations = {
  addToDonation (state, donation) {
    state.donation.push(donation)
  },
  clearDonation (state) {
    state.donation = []
  }
}

const actions = {
  addToDonation ({ commit }, donation) {
    commit('addToDonation', donation)
  },
  clearDonation ({ commit }) {
    commit('clearDonation')
  }
}

const getters = {
  donations: state => state.donations
}

export default {
  state,
  mutations,
  actions,
  getters
}
