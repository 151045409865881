import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import mode from './modules/mode'
import user from './modules/user'
import count from './modules/count'
import organisations from './modules/organisations'
import groups from './modules/groups'
import zakaat from './modules/zakaat'
import transactions from './modules/transactions'
import cart from './modules/cart'
import donation from './modules/donation'
import projects from './modules/projects'
import lookups from './modules/lookups'
import news from './modules/news'
import slider from './modules/slider'
import zakaatCalculator from './modules/zakaatCalculator'
import rates from './modules/rates'
import contact from './modules/contact'
import image from './modules/image'
import layout from './modules/layout'
import ukCheckout from './modules/ukCheckout'
import dailySadaqah from './modules/dailySadaqah'
import verifiedFundraisers from './modules/verifiedFundraisers'
import payment from './modules/payment'
import recurringDonations from './modules/recurringDonations/save'
import recurringDonationsCount from './modules/recurringDonations/count'
import recurringDonationsTransaction from './modules/recurringDonations/transactions'
import recurringDonationsEdit from './modules/recurringDonations/edit'
import recurringDonationsCancel from './modules/recurringDonations/cancel'
import recurringDonationsDownload from './modules/recurringDonations/download'
import recurringDonationsGet from './modules/recurringDonations/get'
import location from './modules/location/location'
import pushNotifications from './modules/appPushNotifications/pushNotifications'

export default createStore({
  plugins: [createPersistedState({
    paths: ['user', 'cart'],
    key: ['vuexaif']
  })],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    mode,
    user,
    count,
    organisations,
    groups,
    zakaat,
    transactions,
    donation,
    projects,
    lookups,
    cart,
    news,
    slider,
    zakaatCalculator,
    rates,
    contact,
    image,
    layout,
    ukCheckout,
    dailySadaqah,
    verifiedFundraisers,
    payment,
    recurringDonations,
    recurringDonationsCount,
    recurringDonationsTransaction,
    recurringDonationsEdit,
    recurringDonationsCancel,
    recurringDonationsDownload,
    recurringDonationsGet,
    location,
    pushNotifications
  }
})
