import axios from 'axios'
import store from '../store'

const createGroup = async (groupDetails) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/add`, groupDetails, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const editGroup = async (groupDetails) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/edit`, groupDetails, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getUserGroups = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/all/${store.state.user.user.userID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getGroupMembers = (groupID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/members/${groupID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const addGroupMembers = (groupID, memberEmail) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/inviteMembers/${groupID}/${memberEmail}`, {}, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const findUser = (email) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/users/donor/exists?aifID=${email}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const verifyGroupInvitee = (token) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/verifyInvite/${token}`, {}, {
    headers: {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const deleteGroup = (groupID) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/delete/${groupID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const leaveGroup = (group) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/${group.groupID}/members/${group.memberID}/leave`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const removeMember = (group) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/${group.groupID}/members/${group.memberID}/remove`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getGroupTransactions = (groupID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/transactions/${groupID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getGroup = (groupID) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/${groupID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { createGroup, getUserGroups, editGroup, addGroupMembers, getGroupMembers, deleteGroup, leaveGroup, findUser, verifyGroupInvitee, removeMember, getGroupTransactions, getGroup }
