import axios from 'axios'
import store from '../../store'

const updateRecurringDonation = (body) => {
  console.log('BODY API', body)
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/update`, body, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const updateCreditCard = (subscriptionId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/creditCard/UpdateCardDetails/${subscriptionId}?returnURL=${process.env.VUE_APP_URL_BASE}/#/managerecurringdonations`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

// THIS ONE v
const initiateUpdateStripeCreditCard = (subscriptionId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/stripe/initiate/UpdateCardDetails/${subscriptionId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

// THIS ONE v
const updateStripeCreditCard = (body) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/stripe/UpdateCardDetails/`, body, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { updateRecurringDonation, updateCreditCard, initiateUpdateStripeCreditCard, updateStripeCreditCard }
