import axios from 'axios'

const getPosters = (startPosition = 0, pageSize = 1000) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/posters/all`, {
    params: {
      startPosition: startPosition,
      pageSize: pageSize,
      sortField: 'lastModified',
      sortOrder: 'DESC'
    }
  })
}
const getSliderPosters = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/posters/live`)
}
const getProjects = (startPosition = 0, pageSize = 1000) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/all`, {
    params: {
      startPosition: startPosition,
      pageSize: pageSize,
      sortField: 'priority',
      sortOrder: 'DESC'
    }
  })
}
const getProjectTypes = (startPosition = 0, pageSize = 1000) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/types`, {
    params: {
      startPosition: startPosition,
      pageSize: pageSize,
      sortField: 'name',
      sortOrder: 'ASC'
    }
  })
}

// const getSAProjects = (startPosition = 0, pageSize = 10) => {
//   return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/sa`, {
//     params: {
//       startPosition: startPosition,
//       pageSize: pageSize,
//       sortField: 'priority',
//       sortOrder: 'DESC'
//     }
//   })
// }

// const getInternationalProjects = (startPosition = 0, pageSize = 10) => {
//   return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/international/`, {
//     params: {
//       startPosition: startPosition,
//       pageSize: pageSize,
//       sortField: 'priority',
//       sortOrder: 'DESC'
//     }
//   })
// }

const getProjectGallery = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/galleries/${id}/`)
}

// const getCurrentAppealsList = (origin, startPosition = 0, pageSize = 50) => {
//   return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/appeals/${origin}`, {
//     params: {
//       startPosition: startPosition,
//       pageSize: pageSize,
//       sortField: 'priority',
//       sortOrder: 'DESC'
//     }
//   })
// }

const getProjectsWithDonationTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/admin/withDonationTypes`)
}

// const getProjectAdmin = (projectAdminID) => {
//   return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/projects/admin/projects`, {
//     params: {
//       projectAdminID: projectAdminID
//     }
//   })
// }

// export { getProjects, getProjectTypes, getPosters, getSAProjects, getInternationalProjects, getProject, getCurrentAppealsList, getProjectAdmin, getProjectsWithDonationTypes }
export { getProjects, getProjectTypes, getPosters, getSliderPosters, getProjectGallery, getProjectsWithDonationTypes }
