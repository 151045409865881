<template >
  <div ref="page">
    <TopNav />
    <div class="main_content" ref="main" >
      <router-view />
    </div>
    <BottomNav height="80" v-if="!isMobile && !isApp"/>
    <MyFooter :scrolled="scrolled" :isHome="$route.name === 'Home'" />
    <BackToTop v-if="scrolled && !isOpenOverlay" :percentage="percentage"/>
    <CookieLaw />
    <transition name="slideInUp">
      <QuickDonateSticky v-if="showQuickDonate" />
    </transition>
    <GetCurrentLocation />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import TopNav from './views/nav/TopNav.vue'
import GetCurrentLocation from './components/location/GetCurrentLocation.vue'
export default {
  components: {
    TopNav,
    GetCurrentLocation,
    MyFooter: defineAsyncComponent(() => import('./views/Footer.vue')),
    BottomNav: defineAsyncComponent(() => import('./views/BottomNav.vue')),
    CookieLaw: defineAsyncComponent(() => import('./components/CookieLaw.vue')),
    BackToTop: defineAsyncComponent(() => import('./components/BackToTop.vue')),
    QuickDonateSticky: defineAsyncComponent(() => import('./views/quickDonate/QuickDonateSticky.vue'))
  },
  data () {
    return {
      isHover: false,
      limitPosition: 20,
      scrolled: false,
      pageHeight: null,
      percentage: null,
      quickDonateScroll: 450,
      quickDonateHide: false
    }
  },
  watch: {
    $route (to, from) {
      this.matchHeight()
      const currentDate = Date.now()
      if (this.loggedInTime && currentDate > this.loggedInTime) {
        this.logout()
        this.$router.push('/')
      }
    },
    theUserID () {
      if (this.theUserID !== null) {
        this.getUserStuff()
      }
    },
    organisations () {
      if (this.organisations) {
        this.getOrganisations()
      }
    },
    groups () {
      if (this.organisations) {
        this.getGroups()
      }
    }
  },
  mounted () {
    this.checkMobile()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  async beforeMount () {
    await this.getContentProjects()
    await this.getAdminProjects()
    this.fetchProjects()
    this.fetchProjectTypes()
    this.fetchCountries()
    await this.fetchDonationTypes()
    await this.fetchSliderPosters()
    this.fetchPosters()
    this.fetchNews()
    this.fetchCurrencies()
    this.fetchHeadOffice()
    this.fetchBranches()
    this.fetchInternational()
    if (this.user && this.userTransactions && this.userTransactions.length < 1) {
      this.fetchUserTransactions()
    }
    if (this.user && !this.section18ATransactions) {
      this.fetch18ATransactions()
    }
    if (this.user && !this.organisations) {
      this.fetchOrganisations()
    }
    if (this.user && this.organisations && this.organisationTransactions && this.organisationTransactions.length < 1) {
      this.getOrganisations()
    }
    if (this.user && !this.groups) {
      this.fetchGroups()
    }
    if (this.user && this.groups && this.userGroupTransactions && this.userGroupTransactions.length < 1) {
      this.getGroups()
    }
    if (this.user && !this.debitOrders) {
      this.fetchUserDebitOrders()
    }
    if (this.user && !this.dailySadaqah) {
      this.fetchUserDailySadaqah()
    }
    if (this.user && !this.pastZakaat) {
      this.fetchPastZakaat()
    }
  },
  computed: {
    ...mapGetters(['isApp', 'isOpenOverlay', 'isMobile', 'user', 'donateAs', 'loggedInTime', 'userDonors', 'userTransactions', 'organisationTransactions', 'organisations', 'debitOrders', 'dailySadaqah', 'userGroupTransactions', 'groups', 'pastZakaat', 'section18ATransactions']),
    backgroundHeight () {
      var height
      if (this.$route.name === 'Home') {
        height = 80
      } else {
        height = 55
      }
      return height
    },
    backgroundHeightMobile () {
      var height
      if (this.$route.name === 'Home') {
        height = 70
      } else {
        height = 55
      }
      return height
    },
    theUserID () {
      if (this.user) {
        return this.user.userID
      } else {
        return null
      }
    },
    showQuickDonate () {
      if (this.isMobile || this.$route.name === 'Home' || this.$route.name === 'Donate Now' || this.$route.name === 'Donation Checkout' || this.$route.name === 'Project') {
        return false
      } else if (this.quickDonateHide) {
        return false
      } else {
        return true
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.matchHeight()
    })
  },
  methods: {
    ...mapActions([
      'setMobile',
      'getContentProjects',
      'getAdminProjects',
      'fetchProjects',
      'fetchMoreProjects',
      'fetchProjectTypes',
      'fetchCountries',
      'fetchDonationTypes',
      'fetchPosters',
      'fetchSliderPosters',
      'fetchNews',
      'fetchCurrencies',
      'fetchHeadOffice',
      'fetchBranches',
      'fetchInternational',
      'fetchOrganisations',
      'fetchUserTransactions',
      'fetchOrganisationTransactions',
      'fetchGroups',
      'fetchGroupTransactions',
      'fetchUserDebitOrders',
      'fetchUserDailySadaqah',
      'fetchPastZakaat',
      'fetch18ATransactions',
      'logout'
    ]),
    matchHeight () {
      setTimeout(() => {
        const height = this.$refs.page.scrollHeight
        this.pageHeight = height
      }, 700)
    },
    handleScroll () {
      this.matchHeight()
      this.isBottomInViewport()
      const totalHeight = window.scrollY + innerHeight
      const percentage = (totalHeight) / (this.pageHeight)
      if (percentage > 1) {
        this.percentage = 1
      } else {
        this.percentage = percentage
      }
      if (window.scrollY > this.limitPosition) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
      // if (this.percentage > 0.8) {
      //   this.quickDonateHide = true
      // } else {
      //   this.quickDonateHide = false
      // }
    },
    getUserStuff () {
      if (this.user) {
        this.fetchGroups()
        this.fetchUserDebitOrders()
        this.fetchUserDailySadaqah()
        this.fetchOrganisations()
        this.fetchUserTransactions()
        this.fetchPastZakaat()
        this.fetch18ATransactions()
      }
    },
    getOrganisations () {
      if (this.organisations && this.organisations.length > 0) {
        this.organisations.forEach(organisation => {
          this.fetchOrganisationTransactions(organisation.organisationID)
        })
      }
    },
    getGroups () {
      if (this.groups && this.groups.length > 0) {
        this.groups.forEach(group => {
          this.fetchGroupTransactions(group.donorGroupID)
        })
      }
    },
    handleResize () {
      this.checkMobile()
    },
    isBottomInViewport () {
      const bottom = this.$refs.main.getBoundingClientRect().bottom
      const scroll = bottom - window.innerHeight
      if (scroll < 100) {
        this.quickDonateHide = true
      } else {
        this.quickDonateHide = false
      }
    },
    checkMobile () {
      if (window.innerWidth < 992) {
        this.setMobile(true)
      } else {
        this.setMobile(false)
      }
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--normal-color);
  min-height: 100vh;
}
.main_content {
  /* min-height: 100vh; */
  padding-bottom: calc(75px + env(safe-area-inset-bottom));
  position: relative;
}
@media (min-width: 992px) {
  .main_content {
    padding-bottom: 0;
  }
}
</style>
