import axios from 'axios'
import store from '../../store'

const downloadRecurringDonationSummary = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/summary/download/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    responseType: 'blob'
  })
}
const downloadRecurringDonationSummaryBreakdown = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/summary/breakdown/download/${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    },
    responseType: 'blob'
  })
}

export { downloadRecurringDonationSummary, downloadRecurringDonationSummaryBreakdown }
