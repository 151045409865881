import { getRecurringDonationCount, getRecurringDonationDailySadaqahCount } from '@/api/recurringDonations/count'

const state = {
  countRecurringDonations: 0,
  countRecurringDonationsStatus: 'ready',
  countDailySadaqahDonations: 0,
  countDailySadaqahDonationsStatus: 'ready'
}

const mutations = {
  setRecurringDonationsCount (state, count) {
    state.countRecurringDonations = count
  },
  setRecurringDonationsCountStatus (state, status) {
    state.countRecurringDonationsStatus = status
  },
  setDailySadaqahDonationsCount (state, count) {
    state.countDailySadaqahDonations = count
  },
  setDailySadaqahDonationsCountStatus (state, status) {
    state.countDailySadaqahDonationsStatus = status
  }
}

const actions = {
  async getRecurringDonationsCount ({ commit, rootState }) {
    commit('setRecurringDonationsCountStatus', 'loading')
    const count = await getRecurringDonationCount(rootState.user.user.userID)
    commit('setRecurringDonationsCount', count.data)
    commit('setRecurringDonationsCountStatus', 'ready')
  },
  async getDailySadaqahDonationsCount ({ commit, rootState }) {
    commit('setDailySadaqahDonationsCountStatus', 'loading')
    const count = await getRecurringDonationDailySadaqahCount(rootState.user.user.userID)
    commit('setDailySadaqahDonationsCount', count.data)
    commit('setDailySadaqahDonationsCountStatus', 'ready')
  }
}

const getters = {
  countRecurringDonations: state => state.countRecurringDonations,
  countRecurringDonationsStatus: state => state.countRecurringDonationsStatus,
  countDailySadaqahDonations: state => state.countDailySadaqahDonations,
  countDailySadaqahDonationsStatus: state => state.countDailySadaqahDonationsStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
