const state = {
  activeSlider: {},
  activeProject: {},
  isInteracted: false
}

const mutations = {
  setActiveSlider (state, slider) {
    state.activeSlider = slider
  },
  setActiveProject (state, project) {
    state.activeProject = project
  },
  setInteracted (state, interacted) {
    state.isInteracted = interacted
  }
}

const actions = {
  setActiveSlider ({ commit }, slider) {
    // this.slider = this.posters.findIndex(poster => poster.project.id === this.activeProject.project.id)
    commit('setActiveSlider', slider)
  },
  setActiveProject ({ commit }, project) {
    commit('setActiveProject', project)
  },
  setInteracted ({ commit }, interacted) {
    commit('setInteracted', interacted)
  }
}

const getters = {
  activeSlider: state => state.activeSlider,
  activeProject: state => state.activeProject,
  isInteracted: state => state.isInteracted
}

export default {
  state,
  mutations,
  actions,
  getters
}
