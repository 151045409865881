import { getProjects, getProjectsWithDonationTypes, getProjectTypes, getPosters, getProjectGallery, getSliderPosters } from '@/api/projects'

const mergeProjects = (projects, projectsWithDonationTypes) => {
  if (!projects.length) {
    return []
  }
  return projects.map(t1 => ({ ...projectsWithDonationTypes.find(t2 => t2.projectID === t1.adminProjectId), ...t1 }))
}

const state = {
  allProjects: [],
  contentProjects: [],
  adminProjects: [],
  projects: [],
  projectsWithDonationTypes: [],
  projectTypes: [],
  posters: [],
  visiblePosters: [],
  currentPosters: [],
  projectGallery: [],
  suggestedPrice: []
}

const mutations = {
  setProjects (state, allProjects) {
    state.allProjects = allProjects
    state.allProjects.filter(project => {
      if (!project.currentAppeal) {
        return false
      }
      state.projects.push(project)
      return true
    })
  },
  setProjectsWithDonationTypes (state, projectsWithDonationTypes) {
    state.projectsWithDonationTypes = projectsWithDonationTypes
  },
  setProjectTypes (state, projectTypes) {
    state.projectTypes = projectTypes
  },
  setContentProjects (state, projects) {
    projects.filter(project => {
      if (!project.currentAppeal) {
        return false
      }
      state.contentProjects.push(project)
      return true
    })
  },
  setAdminProjects (state, projects) {
    projects.filter(project => {
      if (project.archive) {
        return false
      }
      state.adminProjects.push(project)
      return true
    })
  },
  setSuggestedPrice (state, projects) {
    projects.filter(project => {
      if (!project.suggestedPrice) {
        return false
      }
      state.suggestedPrice.push(project)
      return true
    })
  },
  setPosters (state, posters) {
    state.posters = posters
    state.currentPosters = []
    if (state.posters.length < 1) {
      return false
    }
    state.posters.filter(poster => {
      if (!poster.isVisible) {
        return false
      }
      // PUT THIS CODE BACK WHEN HAPPY WITH SLIDERS DISPLAYING
      if (!poster.project) {
        return false
      }
      if (!poster.currentAppeal) {
        return false
      }
      if (poster.currentAppeal) {
        state.currentPosters.push(poster)
        return false
      }
      return true
    })
  },
  setSliderPosters (state, posters) {
    state.visiblePosters = posters
  },
  setProjectGallery (state, gallery) {
    state.projectGallery = gallery
  }
}

const actions = {
  fetchProjects ({ commit }) {
    Promise.all([getProjects(), getProjectsWithDonationTypes()]).then(([projects, projectsWithDonationTypes]) => {
      commit('setProjectsWithDonationTypes', projectsWithDonationTypes.data)
      const result = mergeProjects(projects.data, projectsWithDonationTypes.data)
      commit('setProjects', result)
    })
  },
  getContentProjects ({ commit }) {
    getProjects().then((project) => {
      commit('setContentProjects', project.data)
    })
  },
  getAdminProjects ({ commit }) {
    getProjectsWithDonationTypes().then((project) => {
      commit('setAdminProjects', project.data)
      commit('setSuggestedPrice', project.data)
    })
  },
  fetchMoreProjects ({ commit, state }, { start, count }) {
    getProjects(start, count).then((newProjects) => {
      const result = mergeProjects(newProjects.data, state.projectsWithDonationTypes)
      commit('setProjects', [...result, ...state.allProjects])
    })
  },
  setProjectTypes ({ commit }, projectTypes) {
    commit('setProjectTypes', projectTypes)
  },
  fetchProjectTypes ({ dispatch }) {
    getProjectTypes().then((projectType) => {
      dispatch('setProjectTypes', projectType.data)
    })
  },
  setPosters ({ commit }, posters) {
    commit('setPosters', posters)
  },
  fetchPosters ({ dispatch }) {
    getPosters().then((poster) => {
      dispatch('setPosters', poster.data)
    })
  },
  fetchSliderPosters ({ commit }) {
    getSliderPosters().then((poster) => {
      commit('setSliderPosters', poster.data)
    })
  },
  setGallery ({ commit }, gallery) {
    commit('setProjectGallery', gallery)
  },
  fetchGalleries ({ dispatch }, id) {
    getProjectGallery(id).then((gallery) => {
      dispatch('setGallery', gallery.data)
    })
  }
}

const getters = {
  projects: state => state.projects,
  allProjects: state => state.allProjects,
  projectsWithDonationTypes: state => state.projectsWithDonationTypes,
  projectTypes: state => state.projectTypes,
  posters: state => state.posters,
  // localProjects: state => state.localProjects,
  // internationalProjects: state => state.internationalProjects,
  // localCurrentAppeals: state => state.localCurrentAppeals,
  // internationalCurrentAppeals: state => state.internationalCurrentAppeals,
  visiblePosters: state => state.visiblePosters,
  currentPosters: state => state.currentPosters,
  projectGallery: state => state.projectGallery,
  contentProjects: state => state.contentProjects,
  adminProjects: state => state.adminProjects,
  suggestedPrice: state => state.suggestedPrice
}

export default {
  state,
  mutations,
  actions,
  getters
}
