import axios from 'axios'

const getNews = (startPosition = 0, pageSize = 10) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/news`, {
    params: {
      startPosition: startPosition,
      pageSize: pageSize,
      sortField: 'priority',
      sortOrder: 'DESC'
    }
  })
}

export { getNews }
