import axios from 'axios'

const getFundraisers = (pageSize) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/fundraisers/live/all`, {
    params: {
      startPosition: 0,
      pageSize: pageSize,
      sortField: 'fundraiserID',
      sortOrder: 'ASC'
    }
  })
}
const getFundraisersCount = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/fundraisers/live/all/count`)
}

export {
  getFundraisers,
  getFundraisersCount
}
