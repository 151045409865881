import { getAllRecurringDonations, getAllDailySadaqahRecurringDonations } from '@/api/recurringDonations/get'

const arrangeDescending = (a, b) => {
  if (a.recurringDonationID < b.recurringDonationID) {
    return 1
  }
  if (a.recurringDonationID > b.recurringDonationID) {
    return -1
  }
  return 0
}
const state = {
  allRecurringDonations: [],
  allRecurringDonationsStatus: 'ready',
  allDailySadaqahRecurringDonations: [],
  allDailySadaqahRecurringDonationsStatus: 'ready'
}

const mutations = {
  setAllRecurringDonations (state, val) {
    if (val && val.length > 0) {
      val.forEach((item) => {
        if (state.allRecurringDonations.some(e => e.recurringDonationID === item.recurringDonationID)) {
          console.log('duplicate')
        } else {
          state.allRecurringDonations.push(item)
        }
      })
    }
  },
  setAllRecurringDonationsStatus (state, status) {
    state.allRecurringDonationsStatus = status
  },
  setUpdatedDonation (state, donation) {
    const index = state.allRecurringDonations.findIndex(x => x.recurringDonationID === donation.recurringDonationID)
    state.allRecurringDonations[index] = donation
  },
  setAllDailySadaqahRecurringDonations (state, val) {
    if (val && val.length > 0) {
      val.forEach((item) => {
        if (state.allDailySadaqahRecurringDonations.some(e => e.recurringDonationID === item.recurringDonationID)) {
          console.log('duplicate')
        } else {
          state.allDailySadaqahRecurringDonations.push(item)
        }
      })
    }
  },
  setAllDailySadaqahRecurringDonationsStatus (state, status) {
    state.allDailySadaqahRecurringDonationsStatus = status
  },
  setUpdatedDailySadaqahDonation (state, donation) {
    const index = state.allDailySadaqahRecurringDonations.findIndex(x => x.recurringDonationID === donation.recurringDonationID)
    state.allDailySadaqahRecurringDonations[index] = donation
  }
}

const actions = {
  async getAllRecurringDonations ({ commit, rootState }, options) {
    console.log('getAllRecurringDonations', options)
    commit('setAllRecurringDonationsStatus', 'loading')
    const ret = await getAllRecurringDonations(rootState.user.user.userID, options.startPosition, options.pageSize)
    commit('setAllRecurringDonations', ret.data)
    commit('setAllRecurringDonationsStatus', 'ready')
  },
  updateStoreRecurringDonation ({ commit }, donation) {
    commit('setUpdatedDonation', donation)
  },
  async getAllDailySadaqahRecurringDonations ({ commit, rootState }, options) {
    console.log('getAllDailySadaqahRecurringDonations', options)
    commit('setAllDailySadaqahRecurringDonationsStatus', 'loading')
    const ret = await getAllDailySadaqahRecurringDonations(rootState.user.user.userID, options.startPosition, options.pageSize)
    commit('setAllDailySadaqahRecurringDonations', ret.data)
    commit('setAllDailySadaqahRecurringDonationsStatus', 'ready')
  },
  updateStoreDailySadaqahRecurringDonation ({ commit }, donation) {
    commit('setUpdatedDailySadaqahDonation', donation)
  }
}

const getters = {
  allRecurringDonations: (state) => {
    return state.allRecurringDonations.sort(arrangeDescending).filter(donation => {
      if (donation.recurringDonationStatus && (donation.recurringDonationStatus.recurringDonationStatusID !== 9 && donation.recurringDonationStatus.recurringDonationStatusID !== 6)) {
        return false
      }
      return true
    })
  },
  allRecurringDonationsStatus: state => state.allRecurringDonationsStatus,
  allDailySadaqahRecurringDonations: (state) => {
    return state.allDailySadaqahRecurringDonations.sort(arrangeDescending).filter(donation => {
      if (donation.recurringDonationStatus && (donation.recurringDonationStatus.recurringDonationStatusID !== 9 && donation.recurringDonationStatus.recurringDonationStatusID !== 6)) {
        return false
      }
      return true
    })
  },
  allDailySadaqahRecurringDonationsStatus: state => state.allDailySadaqahRecurringDonationsStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
