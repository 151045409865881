import { getTransactionsCount, getTaxCertificateCount, getPledgeCount, getGroupCount, getOrganisationsCount, getZakaatCount } from '@/api/count'

const state = {
  countDailySadaqa: 0,
  countTransactions: 0,
  countTaxCertificate: 0,
  countPledge: 0,
  countGroup: 0,
  countOrganisations: 0,
  countZakaat: 0,
  countDailySadaqaStatus: 'ready',
  countTransactionsStatus: 'ready',
  countTaxCertificateStatus: 'ready',
  countPledgeStatus: 'ready',
  countGroupStatus: 'ready',
  countOrganisationsStatus: 'ready',
  countZakaatStatus: 'ready'
}

const mutations = {
  setDailySadaqaCount (state, count) {
    state.countDailySadaqa = count
  },
  setTransactionsCount (state, count) {
    state.countTransactions = count
  },
  setTaxCertificateCount (state, count) {
    state.countTaxCertificate = count
  },
  setPledgeCount (state, count) {
    state.countPledge = count
  },
  setGroupCount (state, count) {
    state.countGroup = count
  },
  setOrganisationsCount (state, count) {
    state.countOrganisations = count
  },
  setZakaatCount (state, count) {
    state.countZakaat = count
  },
  setDailySadaqaCountStatus (state, status) {
    state.countDailySadaqaStatus = status
  },
  setTransactionsCountStatus (state, status) {
    state.countTransactionsStatus = status
  },
  setTaxCertificateCountStatus (state, status) {
    state.countTaxCertificateStatus = status
  },
  setPledgeCountStatus (state, status) {
    state.countPledgeStatus = status
  },
  setGroupCountStatus (state, status) {
    state.countGroupStatus = status
  },
  setOrganisationsCountStatus (state, status) {
    state.countOrganisationsStatus = status
  },
  setZakaatCountStatus (state, status) {
    state.countZakaatStatus = status
  }
}

const actions = {
  getAllCounts ({ dispatch }) {
    dispatch('getRecurringDonationsCount', { root: true })
    dispatch('getTransactionsCount')
    dispatch('getTaxCertificateCount')
    dispatch('getPledgeCount')
    dispatch('getGroupCount')
    dispatch('getOrganisationsCount')
    dispatch('getZakaatCount')
  },
  async getTransactionsCount ({ commit, rootState }) {
    commit('setTransactionsCountStatus', 'loading')
    const count = await getTransactionsCount(rootState.user.user.userID)
    commit('setTransactionsCount', count.data)
    commit('setTransactionsCountStatus', 'ready')
  },
  async getTaxCertificateCount ({ commit, rootState }) {
    commit('setTaxCertificateCountStatus', 'loading')
    const count = await getTaxCertificateCount(rootState.user.user.userID)
    commit('setTaxCertificateCount', count.data)
    commit('setTaxCertificateCountStatus', 'ready')
  },
  async getPledgeCount ({ commit, rootState }) {
    commit('setPledgeCountStatus', 'loading')
    const count = await getPledgeCount(rootState.user.user.userID)
    commit('setPledgeCount', count.data)
    commit('setPledgeCountStatus', 'ready')
  },
  async getGroupCount ({ commit, rootState }) {
    commit('setGroupCountStatus', 'loading')
    const count = await getGroupCount(rootState.user.user.userID)
    commit('setGroupCount', count.data)
    commit('setGroupCountStatus', 'ready')
  },
  async getOrganisationsCount ({ commit, rootState }) {
    commit('setOrganisationsCountStatus', 'loading')
    const count = await getOrganisationsCount(rootState.user.user.userID)
    commit('setOrganisationsCount', count.data)
    commit('setOrganisationsCountStatus', 'ready')
  },
  async getZakaatCount ({ commit, rootState }) {
    commit('setZakaatCountStatus', 'loading')
    const count = await getZakaatCount(rootState.user.user.userID)
    commit('setZakaatCount', count.data)
    commit('setZakaatCountStatus', 'ready')
  }
}

const getters = {
  countDailySadaqa: state => state.countDailySadaqa,
  countTransactions: state => state.countTransactions,
  countTaxCertificate: state => state.countTaxCertificate,
  countPledge: state => state.countPledge,
  countGroup: state => state.countGroup,
  countOrganisations: state => state.countOrganisations,
  countZakaat: state => state.countZakaat,
  countDailySadaqaStatus: state => state.countDailySadaqaStatus,
  countTransactionsStatus: state => state.countTransactionsStatus,
  countTaxCertificateStatus: state => state.countTaxCertificateStatus,
  countPledgeStatus: state => state.countPledgeStatus,
  countGroupStatus: state => state.countGroupStatus,
  countOrganisationsStatus: state => state.countOrganisationsStatus,
  countZakaatStatus: state => state.countZakaatStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
