import { userCorrespondence, marketingCorrespondence, userTitles, countries, donationTypes, currencies, bankAccounts } from '@/api/lookups'
const state = {
  titles: [],
  countries: [],
  donationTypes: [],
  correspondenceOptions: [],
  marketingCorrespondenceOptions: [],
  currencies: [],
  bankAccounts: []
}

const mutations = {
  setTitles (state, titles) {
    state.titles = titles
  },
  setCorrespondence (state, correspondence) {
    state.correspondenceOptions = correspondence
  },
  setMarketingCorrespondence (state, correspondence) {
    state.marketingCorrespondenceOptions = correspondence
  },
  setCountries (state, countries) {
    state.countries = countries
  },
  setDonationTypes (state, donationTypes) {
    state.donationTypes = donationTypes
  },
  setCurrencies (state, currencies) {
    state.currencies = currencies
  },
  setBankAccounts (state, bankAccounts) {
    state.bankAccounts = bankAccounts
  }
}

const actions = {
  setTitles ({ commit }, titles) {
    commit('setTitles', titles)
  },
  fetchTitles ({ dispatch }) {
    userTitles().then((titles) => {
      dispatch('setTitles', titles.data)
    })
  },
  setBankAccounts ({ commit }, accounts) {
    commit('setBankAccounts', accounts)
  },
  fetchBankAccounts ({ dispatch }) {
    bankAccounts().then((accounts) => {
      dispatch('setBankAccounts', accounts.data)
    })
  },
  setCorrespondence ({ commit }, correspondence) {
    commit('setCorrespondence', correspondence)
  },
  setMarketingCorrespondence ({ commit }, correspondence) {
    commit('setMarketingCorrespondence', correspondence)
  },
  fetchCorrespondence ({ dispatch }) {
    userCorrespondence().then((correspondence) => {
      dispatch('setCorrespondence', correspondence.data)
      return correspondence.data
    })
  },
  fetchMarketingCorrespondence ({ dispatch }) {
    marketingCorrespondence().then((correspondence) => {
      dispatch('setMarketingCorrespondence', correspondence.data)
      return correspondence.data
    })
  },
  setCountries ({ commit }, countries) {
    commit('setCountries', countries)
  },
  fetchCountries ({ dispatch }) {
    countries().then((countries) => {
      dispatch('setCountries', countries.data)
    })
  },
  setDonationTypes ({ commit }, donationTypes) {
    commit('setDonationTypes', donationTypes)
  },
  fetchDonationTypes ({ dispatch }) {
    donationTypes().then((donationTypes) => {
      dispatch('setDonationTypes', donationTypes.data)
    })
  },
  setCurrencies ({ commit }, currencies) {
    commit('setCurrencies', currencies)
  },
  fetchCurrencies ({ dispatch }) {
    currencies().then((currencies) => {
      dispatch('setCurrencies', currencies.data)
    })
  }
}

const getters = {
  titles: state => state.titles,
  countries: state => state.countries,
  donationTypes: state => state.donationTypes,
  correspondenceOptions: state => state.correspondenceOptions,
  marketingCorrespondenceOptions: state => state.marketingCorrespondenceOptions,
  currencies: state => state.currencies,
  bankAccounts: state => state.bankAccounts
}

export default {
  state,
  mutations,
  actions,
  getters
}
