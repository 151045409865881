import { PushNotifications } from '@capacitor/push-notifications'
import { setUser } from '../../../api/firebase/updateUser.js'

const state = {
  token: null
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  }
}

const actions = {
  async initPushNotifications ({ commit, dispatch }) {
    console.log('this.initPushNotifications')
    try {
      const permissionResult = await PushNotifications.requestPermissions()
      if (permissionResult.receive === 'granted') {
        console.log('Permission Granted')
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      } else {
        console.log('Permission NOT Granted')
      }
      PushNotifications.addListener('registration', (token) => {
        console.info('Push registration success, token: ' + token.value)
        commit('SET_TOKEN', token.value)
        dispatch('updateUserToken', token.value)
      })
      PushNotifications.addListener('registrationError', (error) => {
        console.error('Push Notifications Registration Error:', error)
      })
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.info('Push received: ' + JSON.stringify(notification))
      })
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.info('Push action performed: ' + JSON.stringify(notification))
      })
    } catch (err) {
      console.warn(err)
    }
  },
  updateUserToken ({ rootState }, token) {
    console.log('updateUserToken user', token)
    console.log('rootState ', rootState.user.user.userID)
    const obj = {
      userID: rootState.user.user.userID,
      token
    }
    console.log('OBK', obj)
    return setUser(obj)
  }
}

const getters = {
  token: state => state.token
}

export default {
  state,
  mutations,
  actions,
  getters
}
