import axios from 'axios'
import store from '../../store'

const getRecurringDonationCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/count/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getRecurringDonationDailySadaqahCount = (userId) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/count/dailySadaqa/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
export { getRecurringDonationCount, getRecurringDonationDailySadaqahCount }
