import axios from 'axios'
import store from '../../store'

const cancelRecurringDonation = (body) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/cancel`, body, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { cancelRecurringDonation }
