import axios from 'axios'
import store from '../store'

const getUserOrganisation = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/users/${store.state.user.user.userID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`
    }
  })
}
const getOrganisationAdmin = (id) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/users/user?userId=${id}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`
    }
  })
}
const addNewOrganisation = (organisation) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/save`, organisation, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const addOrganisationImage = (organisation) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/image/upload/${organisation.organisationID}?base64String=${organisation.image}`, {}, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateOrganisation = (organisation) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/update`, organisation, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateOrganisationAddress = (organisation) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/update/addresses/${organisation.organisationID}`, organisation.addressList, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const updateOrganisationContactPeople = (organisation) => {
  return axios.put(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/update/contactPeople/${organisation.organisationID}`, organisation.contactPeople, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const deleteOrganisation = (organisationID) => {
  return axios.delete(`${process.env.VUE_APP_URL_BASE}/adms/api/organisations/remove/${organisationID}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { getUserOrganisation, getOrganisationAdmin, addNewOrganisation, addOrganisationImage, updateOrganisation, updateOrganisationAddress, updateOrganisationContactPeople, deleteOrganisation }
