import axios from 'axios'
import store from '../../store'
const saveRecurringDonation = (body) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/recurringDonation/save`, body, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const initiatePayFastSubscription = (transactionRef, returnUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/subscriptions/initiate/payFast/creditCard`, {
    subscriptionReference: transactionRef,
    returnUrl: returnUrl,
    cancelUrl: returnUrl,
    notifyUrl: `${process.env.VUE_APP_URL_BASE}/adms/api/donations/subscriptions/call-back/pay-fast`,
    captchaToken: captchaToken
  }, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

// THIS ONE v
const initiateStripeSubscription = (transactionRef, returnUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/subscriptions/initiate/stripe/creditCard`, {
    subscriptionReference: transactionRef,
    returnUrl: returnUrl,
    captchaToken: captchaToken
  }, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { saveRecurringDonation, initiatePayFastSubscription, initiateStripeSubscription }
