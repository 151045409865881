import axios from 'axios'
import store from '../../store'

const getPayFastSubscriptionStatus = (subscriptionReference) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/subscriptions/creditCard/payFast/verify/${subscriptionReference}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

const verifyStripeSubscription = (subscriptionReference) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/subscriptions/creditCard/stripe/verify/${subscriptionReference}`, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export {
  getPayFastSubscriptionStatus,
  verifyStripeSubscription
}
