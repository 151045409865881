import axios from 'axios'

const bankingDetails = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/contacts/bankingDetails`)
}

const donationTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/donationTypes/all`)
}

const currencies = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/currencies/all`)
}

const userCorrespondence = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/correspondances/notificationType`)
}
const marketingCorrespondence = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/correspondances/marketing`)
}
const userTitles = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/userTitle/all`)
}

const countries = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/countries/all`)
}

const businessAssetTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/businessAssetTypes/all`)
}

const cashAssetTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/cashAssetTypes/all`)
}

const debtorsAssetTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/debtorsAssetTypes/all`)
}

const investmentAssetTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/investmentAssetTypes/all`)
}

const policiesAssetTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/policiesAssetTypes/all`)
}

const basicLiabilityTypes = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/zakaatBasicLiabilityTypes/all`)
}
const bankAccounts = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/bankAccounts/all`)
}

export { bankingDetails, bankAccounts, donationTypes, currencies, userCorrespondence, marketingCorrespondence, userTitles, countries, businessAssetTypes, cashAssetTypes, debtorsAssetTypes, investmentAssetTypes, policiesAssetTypes, basicLiabilityTypes }
