import axios from 'axios'
import store from '../store'
const saveDailySadaqahAnon = (details) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/dailySadaqah/save`, details, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const saveDailySadaqah = (details) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/dailySadaqah/za/logged-in/save`, details, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
export {
  saveDailySadaqah,
  saveDailySadaqahAnon
}
