import { updateRecurringDonation, updateCreditCard } from '@/api/recurringDonations/edit'

const actions = {
  async updateRecurringDonation ({ dispatch }, val) {
    console.log('Update, val store', val)
    try {
      const editReturn = await updateRecurringDonation(val)
      console.log('editReturn', editReturn)
      await dispatch('updateStoreRecurringDonation', val, { root: true })
      return editReturn
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  },
  async updateCardDetails ({ _ }, val) {
    console.log('UpdateCArd, val store', val)
    try {
      const editReturn = await updateCreditCard(val)
      console.log('editReturn', editReturn)
      return editReturn
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  }
}

export default {
  actions
}
