import { createGroup, getUserGroups, addGroupMembers, getGroupMembers, editGroup, deleteGroup, leaveGroup, findUser, verifyGroupInvitee, removeMember, getGroupTransactions, getGroup } from '@/api/groups'
const state = {
  groups: null,
  groupsStatus: null,
  groupMembersStatus: null,
  myGroups: null,
  otherGroups: null,
  groupMembers: null,
  groupTransactions: null,
  groupTransactionStatus: null,
  group: null
}

const mutations = {
  setGroups (state, groups) {
    state.groups = groups
  },
  setGroupStatus (state, status) {
    state.groupsStatus = status
  },
  setMembersStatus (state, status) {
    state.groupMembersStatus = status
  },
  setMyGroups (state, groups) {
    state.myGroups = groups
  },
  setOtherGroups (state, groups) {
    state.otherGroups = groups
  },
  setGroupMembers (state, groupMembers) {
    state.groupMembers = groupMembers
  },
  setGroupTransaction (state, groupTransactions) {
    state.groupTransactions = groupTransactions
  },
  setGroupTransactionStatus (state, status) {
    state.groupTransactionStatus = status
  },
  setGroup (state, group) {
    state.group = group
  }
}

const actions = {
  async setGroups ({ commit, dispatch, rootState }, groups) {
    await dispatch('setGroupStatus', 'loading')
    const groupManage = []
    const groupMember = []
    const allGroups = []
    if (groups && groups.length > 0) {
      await groups.forEach((group) => {
        if (!group.donorGroupDeleted) {
          if (group.groupFounder.userID === rootState.user.user.userID) {
            groupManage.push(group)
          } else {
            groupMember.push(group)
          }
          allGroups.push(group)
        }
      })
    }
    await commit('setMyGroups', groupManage)
    await commit('setOtherGroups', groupMember)
    await commit('setGroups', allGroups)
    await dispatch('setGroupStatus', 'ready')
  },
  async createGroup ({ dispatch }, groupDetails) {
    await dispatch('setGroupStatus', 'adding')
    await createGroup(groupDetails)
    await dispatch('setGroupStatus', 'added')
    await dispatch('fetchGroups')
  },
  async editGroup ({ dispatch }, groupDetails) {
    await dispatch('setGroupStatus', 'editing')
    await editGroup(groupDetails)
    await dispatch('setGroupStatus', 'edited')
    await dispatch('fetchGroups')
  },
  async deleteGroup ({ dispatch }, groupID) {
    await dispatch('setGroupStatus', 'deleting')
    await deleteGroup(groupID)
    await dispatch('setGroupStatus', 'deleted')
    await dispatch('fetchGroups')
  },
  async leaveGroup ({ dispatch }, group) {
    await dispatch('setMembersStatus', 'removing')
    await leaveGroup(group)
    await dispatch('setMembersStatus', 'removed')
    await dispatch('fetchGroups')
  },
  async removeGroupMember ({ dispatch }, group) {
    await dispatch('setMembersStatus', 'removing')
    await removeMember(group)
    await dispatch('fetchGroupMembers', group.groupID)
  },
  async fetchGroups ({ dispatch }) {
    await dispatch('setGroupStatus', 'loading')
    const groups = await getUserGroups()
    await dispatch('setGroupStatus', 'ready')
    await dispatch('setGroups', groups.data)
  },
  async setGroupStatus ({ commit }, status) {
    await commit('setGroupStatus', status)
  },
  async addGroupMembers ({ dispatch }, details) {
    await dispatch('setMembersStatus', 'adding')
    await addGroupMembers(details.groupID, details.email)
    await dispatch('setMembersStatus', 'added')
  },
  async checkUser ({ dispatch }, details) {
    const userReturn = await findUser(details.email)
    if (userReturn.data === true) {
      await dispatch('addGroupMembers', details)
    } else {
      await dispatch('setMembersStatus', 'notFound')
    }
  },
  async verifyInvite ({ dispatch }, token) {
    await dispatch('setMembersStatus', 'joining')
    const verifyReturn = await verifyGroupInvitee(token)
    if (verifyReturn.data === true) {
      await dispatch('setMembersStatus', 'ready')
    } else {
      await dispatch('setMembersStatus', 'error')
    }
  },
  async setMembersStatus ({ commit }, status) {
    await commit('setMembersStatus', status)
  },
  async fetchGroupMembers ({ dispatch }, groupID) {
    await dispatch('setMembersStatus', 'loading')
    const groupMembersReturn = await getGroupMembers(groupID)
    await dispatch('setMembersStatus', 'ready')
    await dispatch('setMembers', groupMembersReturn.data)
  },
  async setMembers ({ commit, dispatch }, members) {
    await dispatch('setMembersStatus', 'loading')
    await commit('setGroupMembers', members)
    await dispatch('setMembersStatus', 'ready')
  },

  async fetchGroupTransactions ({ dispatch }, groupID) {
    await dispatch('setGroupTransactionStatus', 'loading')
    const groupReturn = await getGroup(groupID)
    await dispatch('setGroup', groupReturn.data)
    const groupTransactionsReturn = await getGroupTransactions(groupID)
    await dispatch('setGroupTransactionStatus', 'ready')
    await dispatch('setGroupTransactions', groupTransactionsReturn.data)
  },
  async setGroup ({ commit }, group) {
    await commit('setGroup', group)
  },
  async setGroupTransactions ({ commit, dispatch }, transactions) {
    await dispatch('setGroupTransactionStatus', 'loading')
    await commit('setGroupTransaction', transactions)
    await dispatch('setGroupTransactionStatus', 'ready')
  },
  async setGroupTransactionStatus ({ commit }, status) {
    await commit('setGroupTransactionStatus', status)
  }
}

const getters = {
  groups: state => state.groups,
  myGroups: state => state.myGroups,
  otherGroups: state => state.otherGroups,
  groupsStatus: state => state.groupsStatus,
  groupMembersStatus: state => state.groupMembersStatus,
  groupMembers: state => state.groupMembers,
  groupTransactions: state => state.groupTransactions,
  group: state => state.group,
  groupTransactionStatus: state => state.groupTransactionStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
