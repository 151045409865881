import { headOfficeDetails, branchContactDetails, internationalContactDetails, sendEnquiry, subscribe, contactReason } from '@/api/contact'

const state = {
  headOffice: [],
  branches: [],
  internationalBranches: [],
  contactReasons: []
}

const mutations = {
  setHeadOffice (state, headOffice) {
    state.headOffice = headOffice
  },
  setBranches (state, branches) {
    state.branches = branches
  },
  setInternational (state, branches) {
    state.internationalBranches = branches
  },
  setContactReasons (state, reasons) {
    state.contactReasons = reasons
  }
}

const actions = {
  setHeadOffice ({ commit }, headOffice) {
    commit('setHeadOffice', headOffice)
  },
  fetchHeadOffice ({ dispatch }) {
    headOfficeDetails().then((headOffice) => {
      dispatch('setHeadOffice', headOffice.data)
    })
  },
  setBranches ({ commit }, branch) {
    commit('setBranches', branch)
  },
  async getContactReason ({ commit }) {
    const ret = await contactReason()
    commit('setContactReasons', ret.data)
  },
  fetchBranches ({ dispatch }) {
    branchContactDetails().then((branch) => {
      dispatch('setBranches', branch.data)
    })
  },
  setInternational ({ commit }, branch) {
    commit('setInternational', branch)
  },
  fetchInternational ({ dispatch }) {
    internationalContactDetails().then((branch) => {
      dispatch('setInternational', branch.data)
    })
  },
  async sendForm ({ _ }, form) {
    const resp = await sendEnquiry(form)
    if (resp) {
      return resp.data
    } else {
      return 0
    }
  },
  async subscribeTo ({ commit }, form) {
    const resp = await subscribe(form)
    return resp.data
    // await subscribe(form)
  }
}

const getters = {
  headOffice: state => state.headOffice,
  branches: state => state.branches,
  internationalBranches: state => state.internationalBranches,
  contactReasons: state => state.contactReasons
}

export default {
  state,
  mutations,
  actions,
  getters
}
