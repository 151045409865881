const state = {
  isOpenOverlay: false,
  isMobile: false
}

const mutations = {
  setOpenOverlay (state, status) {
    state.isOpenOverlay = status
  },
  SET_MOBILE (state, status) {
    state.isMobile = status
  }
}

const actions = {
  async setOpen ({ commit }, status) {
    await commit('setOpenOverlay', status)
  },
  async setMobile ({ commit }, status) {
    await commit('SET_MOBILE', status)
  }

}

const getters = {
  isOpenOverlay: state => state.isOpenOverlay,
  isMobile: state => state.isMobile
}

export default {
  state,
  mutations,
  actions,
  getters
}
