import { login, getUserInfo, findUser, registerDonor, resetPassword, resetPasswordVerify, updateCredentials, updateDetails, addProfileImage, updatePrimaryAddress, updateSecondaryAddress, updateSecondaryEmail, updateSecondaryPhone, setVolunteer, setAnonVolunteer, getUserDonors, checkUserExists, verifyIDNumber } from '@/api/user'
const state = {
  user: null,
  userDonors: null,
  jwt: null,
  userStatus: null,
  loggedInTime: null,
  loginPopup: false,
  donateAs: null
  // transactions: null
}

const mutations = {
  setUser (state, user) {
    state.user = user
    state.donateAs = user
  },
  setDonateAs (state, user) {
    state.donateAs = user
  },
  setUserStatus (state, status) {
    state.userStatus = status
  },
  setLoginPopupStatus (state, status) {
    state.loginPopup = status
  },
  setUserDonors (state, donors) {
    state.userDonors = donors
  },
  setJWT (state, jwt) {
    state.jwt = jwt
  },
  setLoginTime (state, time) {
    state.loggedInTime = time
  }
  // setTransactions (state, transactions) {
  //   state.transactions = transactions
  // }
}

const actions = {
  async setUserStatus ({ commit }, status) {
    await commit('setUserStatus', status)
  },
  async setLoginTime ({ commit }, time) {
    await commit('setLoginTime', time)
  },
  async setLoginPopupStatus ({ commit }, status) {
    await commit('setLoginPopupStatus', status)
  },
  async setDonateAs ({ commit }, donor) {
    await commit('setDonateAs', donor)
    const userDonorReponse = await getUserDonors()
    commit('setUserDonors', userDonorReponse.data)
  },
  async login ({ commit, dispatch, rootState }, auth) {
    dispatch('setUserStatus', 'loading')
    const loginResponse = await login(auth)
    const dateTime = Date.now() + (24 * 60 * 60 * 1000)
    if (loginResponse === 'error') {
      dispatch('setUserStatus', 'error')
    } else {
      dispatch('setUserStatus', 'logging in')
      commit('setJWT', loginResponse.data)
      dispatch('setLoginTime', dateTime)
      const userInfoReponse = await getUserInfo(auth.username)
      await commit('setUser', userInfoReponse.data)
      const userDonorReponse = await getUserDonors()
      commit('setUserDonors', userDonorReponse.data)
      dispatch('setUserStatus', 'logged in')
      if (rootState.mode.isApp) {
        dispatch('initPushNotifications', { root: true })
      }
    }
  },
  async registerDonor ({ dispatch }, user) {
    dispatch('setUserStatus', 'loading')
    const registerResponse = await registerDonor(user)
    if (registerResponse) {
      await dispatch('login', { username: user.username, password: user.password })
    }
    return 1
  },
  async forgotPassword ({ commit }, auth) {
    const forgotResponse = await resetPassword(auth)
    return forgotResponse
  },
  async resetPasswordVerify ({ commit }, auth) {
    const resetPassword = await resetPasswordVerify(auth)
    return resetPassword
  },

  async logout ({ commit, dispatch }) {
    await commit('setUser', null)
    await commit('setJWT', null)
    await commit('setLoginTime', null)
    await commit('setUserDonors', null)
    await commit('setUserStatus', null)
    await commit('setDonateAs', null)
    console.log('HERERERE')
    await dispatch('setUserTransactions', null, { root: true })
    await dispatch('set18ATransactions', null, { root: true })
    await dispatch('setOrganisationTransactions', null, { root: true })
    await dispatch('setGroupTransactions', null, { root: true })
    await dispatch('setUserDebitOrders', null, { root: true })
    await dispatch('setUserDailySadaqah', null, { root: true })
    await dispatch('setOrganisations', null, { root: true })
    await dispatch('setGroups', null, { root: true })
    await dispatch('setZakaat', null, { root: true })
  },
  // setTransactions ({ commit }, transactions) {
  //   commit('setTransactions', transactions)
  // },
  // fetchTransactions ({ dispatch, state }, id) {
  //   getTransactions(id, state.jwt).then((transactions) => {
  //     dispatch('setTransactions', transactions.data)
  //   })
  // },
  async updateUserDetails ({ commit }, userDetails) {
    await updateDetails(userDetails)
    const userInfoReponse = await getUserInfo(userDetails.email)
    commit('setUser', userInfoReponse.data)
  },
  async updateUserAddress ({ commit }, userDetails) {
    await updatePrimaryAddress(userDetails)
    const userInfoReponse = await getUserInfo(userDetails.email)
    commit('setUser', userInfoReponse.data)
  },
  async updateUserSecondaryAddress ({ commit, state }, userDetails) {
    await updateSecondaryAddress(userDetails)
    const userInfoReponse = await getUserInfo(state.user.email)
    commit('setUser', userInfoReponse.data)
  },
  async updateUserSecondaryEmail ({ commit, state }, userDetails) {
    await updateSecondaryEmail(userDetails)
    const userInfoReponse = await getUserInfo(state.user.email)
    commit('setUser', userInfoReponse.data)
  },
  async updateUserSecondaryPhone ({ commit, state }, userDetails) {
    await updateSecondaryPhone(userDetails)
    const userInfoReponse = await getUserInfo(state.user.email)
    commit('setUser', userInfoReponse.data)
  },
  async updateUserCredentials ({ commit }, userDetails) {
    await updateCredentials(userDetails)
    const userInfoReponse = await getUserInfo(userDetails.email)
    commit('setUser', userInfoReponse.data)
  },
  async volunteer ({ state }, details) {
    if (state.user) {
      await setVolunteer(details)
    } else {
      const ret = await checkUserExists(details.userRegistrationDto.email)
      if (!ret.data) {
        await setAnonVolunteer(details)
      } else {
        return 'You are already a volunteer'
      }
    }
  },
  async saveProfileImage ({ _ }, image) {
    await addProfileImage(image)
  },
  async findUser ({ _ }, details) {
    const result = await findUser(details)
    return result.data
  },
  async checkUserExists ({ _ }, email) {
    const result = await checkUserExists(email)
    return result.data
  },
  async verifyIDNumber ({ _ }, idNumber) {
    const result = await verifyIDNumber(idNumber)
    return result.data
  }

}

const getters = {
  user: state => state.user,
  userDonors: state => state.userDonors,
  jwt: state => state.jwt,
  userStatus: state => state.userStatus,
  loginPopup: state => state.loginPopup,
  loggedInTime: state => state.loggedInTime,
  donateAs: state => state.donateAs
  // transactions: state => state.transactions
}

export default {
  state,
  mutations,
  actions,
  getters
}
