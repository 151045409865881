import axios from 'axios'

const sendEnquiry = async (form) => {
  const details = {
    fullName: form.fullName,
    email: form.email,
    cellNumber: form.cellNumber,
    message: form.message,
    enquirySubject: form.enquirySubject
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/enquiries/save`,
    details
  ).then((res) => {
    return res
  }).catch((err) => {
    return err
  })
}
const subscribe = async (form) => {
  const details = {
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    cell: form.cell,
    consented: form.consented
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/subscriptions/save`, details)
}
const contactReason = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/enquiryTypes/all`)
}
const headOfficeDetails = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/contacts/headoffice`)
}
const branchContactDetails = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/contacts/sa`)
}
const internationalContactDetails = () => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/lookup/contacts/international`)
}

export { headOfficeDetails, branchContactDetails, internationalContactDetails, sendEnquiry, subscribe, contactReason }
