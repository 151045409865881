import { getLocation } from '@/api/location/location'

const state = {
  selectedLocation: null
}

const mutations = {
  SET_LOCATION (state, country) {
    state.selectedLocation = country
  }
}
const actions = {
  async getLocation ({ _ }) {
    try {
      const location = await getLocation()
      return location.data
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  },
  async setLocation ({ commit }, country) {
    commit('SET_LOCATION', country)
  }
}

const getters = {
  selectedLocation: state => state.selectedLocation
}

export default {
  state,
  mutations,
  actions,
  getters
}
